@font-face {
  src: local("Cairo Regular"), local("Cairo-Regular"), url(../sass/fonts/Cairo-Regular.ttf);
  font-family: Cairo; }
@font-face {
  src: local("Cairo Bold"), local("Cairo-Bold"), url(../sass/fonts/Cairo-Bold.ttf);
  font-family: Cairo;
  font-weight: bold; }
@font-face {
  font-family: lato;
  src: url(../sass/fonts/29LT_Bukra.ttf);
  font-weight: normal;
  font-display: swap; }
@font-face {
  font-family: lato;
  src: url(../sass/fonts/29LT_Bukra_Bold.TTF);
  font-weight: bold;
  font-display: swap; }
.ciecle, .ant-tooltip .ant-tooltip-inner, header.user-header .notify-holder .notify-count, header.user-header .profile-holder, header.user-header .profile-holder::after, .notify-menu-wrapper > li:last-of-type, .admin-modal-wrapper .ant-modal-content .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center; }

header.user-header, header.grid-action-header, .admin-modal-wrapper .ant-modal-content .ant-modal-header, .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between; }

/************LINKS ********************/
/************BTNS ********************/
/************MEDIA QUERY ********************/
/*****************prfix*************/
/*****************input placeholder*************/
.pb-24 {
  padding-bottom: 1.5rem !important; }

.pt-24 {
  padding-top: 1.5rem !important; }

.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.px-50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.d-flex {
  display: flex; }

.align-items-center {
  align-items: center; }

.pl-20 {
  padding-left: 1.25rem !important; }
  body.k-rtl .pl-20 {
    padding-left: 0 !important;
    padding-right: 1.25rem !important; }

.h-90 {
  height: 90%; }

.ml-20 {
  margin-left: 1.25rem !important; }
  body.k-rtl .ml-20 {
    margin-left: 0 !important;
    margin-right: 1.25rem !important; }

.text-underline {
  text-decoration: underline !important; }

.text-underline-none {
  text-decoration: none !important; }

.p-relative {
  position: relative !important; }

.cursor-p {
  cursor: pointer; }

.h-auto {
  height: auto !important; }

.text-nowrap {
  white-space: nowrap; }

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020; }

.w-100 {
  width: 100% !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }
  body.rtl .text-right {
    text-align: left !important; }

.text-left {
  text-align: left !important; }
  body.rtl .text-left {
    text-align: right !important; }

.fit-cover {
  object-fit: cover; }

.visibility-hidden {
  visibility: hidden !important; }

.flex_justfy_center {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.text_right {
  text-align: right; }
  body.rtl .text_right {
    text-align: left; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
/*******  created 26/3/2019 ************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  font-family: lato, Cairo; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body,
html {
  font-size: 14px;
  overflow-x: hidden;
  width: 100%;
  height: 100%; }

ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: none; }

input:focus {
  outline: none !important; }

a:active,
a:hover {
  outline: 0;
  text-decoration: none; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none;
  background-color: transparent;
  border: none;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
section::-webkit-scrollbarb,
main::-webkit-scrollbar,
.ant-layout::-webkit-scrollbar {
  background-color: #f4f4f4 !important;
  width: 7px !important; }

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb,
.ant-layout::-webkit-scrollbar-thumb {
  background-color: #001529 !important; }

.ant-form-item-with-help {
  margin-bottom: 24px; }

.font-weight-600 {
  font-weight: 600; }

#root {
  min-height: 100% !important; }

.height_90 {
  min-height: 90vh !important; }

.text_center {
  text-align: center; }

.no_background {
  background: none !important; }

.width_60 {
  width: 60% !important; }

.ant-modal-content {
  border-radius: 20px; }

.ant-modal-body {
  padding: 0px 20px 30px 20px; }

.gutter-example .ant-row > div {
  background: transparent;
  border: 0; }

.gutter-box {
  padding: 5px 0; }

.ant-btn {
  border: none;
  box-shadow: none; }

.m_0 {
  margin: 0 !important; }

strong {
  font-weight: bolder !important; }

.ant-modal-header {
  border-radius: 20px;
  border: none;
  padding-top: 40px; }
  .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1rem;
    color: #000;
    font-weight: bold; }

.ant-modal-footer {
  border: none;
  text-align: center;
  padding-bottom: 40px; }

.ant-select-disabled {
  opacity: 0.5; }

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  min-height: 80vh; }

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: transparent; }

/***********************headeings*********************/
h1, h2, h3, h4, h5, h6 {
  color: #242424;
  line-height: normal; }

h1 {
  font-size: 2.1875rem; }

h2 {
  font-size: 1.875rem; }

h3 {
  font-size: 1.5625rem; }

h4 {
  font-size: 1.25rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem; }

p {
  font-size: 0.875rem;
  font-weight: normal; }

/*************TEXT******************/
.black_text {
  color: #000; }

.white_text {
  color: #fff !important; }

.primary_text {
  color: #001529; }

.secondary_text {
  color: #bf9c51; }

.dark-blue-text {
  color: #1f883c; }

/*disabled link*/
.isdisabled, .Primary_fill:disabled, .Primary_outline:disabled, .white_fill:disabled, .secondary_fill:disabled, .secondary_outline:disabled, .grayscale_fill:disabled, .grayscale_outline:disabled, .primary_link:disabled, .secondary_link:disabled, .Grayscale_link:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

/*backgrounds*/
.bg_white {
  background-color: #fff; }

.bg_primary {
  background-color: #001529; }

.bg_secondary {
  background-color: #bf9c51; }

/*Primary (Fill)*/
.Primary_fill {
  background-color: #001529;
  border: 1px solid #001529;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize;
  box-shadow: 0 6px 20px 0 rgba(0, 1, 4, 0.41); }
  @media only screen and (max-width: 768px) {
    .Primary_fill {
      padding: 0 12px; } }
  .Primary_fill.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_fill:hover {
  background-color: #001529;
  border: 1px solid #001529;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_fill:hover {
      padding: 0 12px; } }
  .Primary_fill:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_fill:active {
  background-color: #001529;
  border: 1px solid #001529;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_fill:active {
      padding: 0 12px; } }
  .Primary_fill:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_fill:disabled {
  background-color: #001529;
  border: 1px solid #001529;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_fill:disabled {
      padding: 0 12px; } }
  .Primary_fill:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

/*Primary (Outline)*/
.Primary_outline {
  background-color: transparent;
  border: 1px solid #001529;
  color: #001529;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_outline {
      padding: 0 12px; } }
  .Primary_outline.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_outline:hover {
  background-color: transparent;
  border: 1px solid #001529;
  color: #001529;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_outline:hover {
      padding: 0 12px; } }
  .Primary_outline:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_outline:active {
  background-color: transparent;
  border: 1px solid #001529;
  color: #001529;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_outline:active {
      padding: 0 12px; } }
  .Primary_outline:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.Primary_outline:disabled {
  background-color: transparent;
  border: 1px solid #001529;
  color: #001529;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .Primary_outline:disabled {
      padding: 0 12px; } }
  .Primary_outline:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

/*white (Fill)*/
.white_fill {
  background-color: #fff;
  border: 1px solid #fff;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .white_fill {
      padding: 0 12px; } }
  .white_fill.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.white_fill:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .white_fill:hover {
      padding: 0 12px; } }
  .white_fill:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.white_fill:active {
  background-color: #fff;
  border: 1px solid #fff;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .white_fill:active {
      padding: 0 12px; } }
  .white_fill:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.white_fill:disabled {
  background-color: #fff;
  border: 1px solid #fff;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .white_fill:disabled {
      padding: 0 12px; } }
  .white_fill:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

/*Secondary (Fill)*/
.secondary_fill {
  background-color: #bf9c51;
  border: 1px solid #bf9c51;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_fill {
      padding: 0 12px; } }
  .secondary_fill.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_fill:hover {
  background-color: #bf9c51;
  border: 1px solid #bf9c51;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_fill:hover {
      padding: 0 12px; } }
  .secondary_fill:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_fill:active {
  background-color: #bf9c51;
  border: 1px solid #bf9c51;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_fill:active {
      padding: 0 12px; } }
  .secondary_fill:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_fill:disabled {
  background-color: #bf9c51;
  border: 1px solid #bf9c51;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_fill:disabled {
      padding: 0 12px; } }
  .secondary_fill:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

/*secondary (Outline)*/
.secondary_outline {
  background-color: #fff;
  border: 1px solid #bf9c51;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_outline {
      padding: 0 12px; } }
  .secondary_outline.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_outline:hover {
  background-color: #fff;
  border: 1px solid #bf9c51;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_outline:hover {
      padding: 0 12px; } }
  .secondary_outline:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_outline:active {
  background-color: #fff;
  border: 1px solid #bf9c51;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_outline:active {
      padding: 0 12px; } }
  .secondary_outline:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.secondary_outline:disabled {
  background-color: #fff;
  border: 1px solid #bf9c51;
  color: #bf9c51;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .secondary_outline:disabled {
      padding: 0 12px; } }
  .secondary_outline:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_fill {
  background-color: #242424;
  border: 1px solid #242424;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_fill {
      padding: 0 12px; } }
  .grayscale_fill.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_fill:hover {
  background-color: #242424;
  border: 1px solid #242424;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_fill:hover {
      padding: 0 12px; } }
  .grayscale_fill:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_fill:active {
  background-color: #242424;
  border: 1px solid #242424;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_fill:active {
      padding: 0 12px; } }
  .grayscale_fill:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_fill:disabled {
  background-color: #242424;
  border: 1px solid #242424;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_fill:disabled {
      padding: 0 12px; } }
  .grayscale_fill:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_outline {
  background-color: #fff;
  border: 1px solid #242424;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_outline {
      padding: 0 12px; } }
  .grayscale_outline.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_outline:hover {
  background-color: #fff;
  border: 1px solid #242424;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_outline:hover {
      padding: 0 12px; } }
  .grayscale_outline:hover.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_outline:active {
  background-color: #fff;
  border: 1px solid #242424;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_outline:active {
      padding: 0 12px; } }
  .grayscale_outline:active.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.grayscale_outline:disabled {
  background-color: #fff;
  border: 1px solid #242424;
  color: #242424;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 37px !important;
  font-size: 0.875rem;
  border-radius: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize; }
  @media only screen and (max-width: 768px) {
    .grayscale_outline:disabled {
      padding: 0 12px; } }
  .grayscale_outline:disabled.small-btn {
    min-height: 30px !important;
    padding: 0 15px; }

.ciecle {
  max-width: 23px;
  max-height: 23px;
  width: 23px;
  height: 23px;
  border-radius: 50% !important; }

.btn_lg {
  min-width: 200px !important; }

/******************links***************/
/*primary_link*/
.primary_link {
  color: #001529;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize; }
  .primary_link:hover {
    text-decoration: underline; }
  .primary_link:active, .primary_link:focus {
    text-decoration: none; }
  .primary_link:disabled {
    color: red !important; }
  .primary_link:hover {
    color: #1f883c; }

/*secondary_link*/
.secondary_link {
  color: #bf9c51;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize; }
  .secondary_link:hover {
    text-decoration: underline; }
  .secondary_link:active, .secondary_link:focus {
    text-decoration: none; }
  .secondary_link:disabled {
    color: red !important; }
  .secondary_link:hover {
    color: #c30c00; }

/*Grayscale_link*/
.Grayscale_link {
  color: #242424;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize; }
  .Grayscale_link:hover {
    text-decoration: underline; }
  .Grayscale_link:active, .Grayscale_link:focus {
    text-decoration: none; }
  .Grayscale_link:disabled {
    color: red !important; }
  .Grayscale_link:hover {
    color: #000; }

.ant-radio-group-outline .ant-radio-wrapper {
  color: #242424;
  font-size: 15px;
  font-weight: bold; }
  .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-color: #7e7e7e;
    color: #7e7e7e;
    box-shadow: none !important; }
    .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
      background-color: #001529; }
  .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    border-color: #001529; }
  .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
    color: #001529; }
  .ant-radio-group-outline .ant-radio-wrapper:hover {
    color: #001529; }
    .ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: #001529; }
      .ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner:after {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1; }
  .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio-inner {
    background-color: #fff; }
  .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled:hover .ant-radio-inner:after {
    opacity: 0; }

.ant-checkbox-wrapper {
  color: #242424;
  font-size: 15px;
  font-weight: bold; }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-color: #7e7e7e;
    box-shadow: none !important; }
    .ant-checkbox-wrapper .ant-checkbox-inner.ant-checkbox-wrapper-checked .ant-checkbox-inner {
      border-color: #001529; }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: #001529;
    border-color: #001529; }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #001529; }
  .ant-checkbox-wrapper:hover .ant-checkbox + span {
    color: #001529; }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
    background-color: #fff; }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-checkbox + span {
    color: rgba(0, 0, 0, 0.25); }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-radio-inner:after {
    opacity: 0; }

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #001529; }

.ant-switch {
  min-width: 50px !important;
  height: 16px !important;
  margin-left: 5px !important;
  background-color: #f5f5f5; }
  .ant-switch::after {
    width: 25px;
    height: 25px;
    top: -6px;
    margin-left: -5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa; }
  .ant-switch.ant-switch-checked {
    background-color: #bf9c51; }
    .ant-switch.ant-switch-checked::after {
      margin-left: 5px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff; }

.switch-input-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .switch-input-holder span {
    font-size: 14px;
    font-weight: bold;
    color: #5a5a5a; }
  .switch-input-holder .ant-form-item {
    display: flex;
    align-items: center; }
    .switch-input-holder .ant-form-item .ant-form-item-label {
      position: static; }
      .switch-input-holder .ant-form-item .ant-form-item-label label {
        margin-right: 15px;
        font-size: 14px; }
        body.rtl .switch-input-holder .ant-form-item .ant-form-item-label label {
          margin-left: 15px;
          margin-right: 0; }

.counter-input-holder {
  display: flex;
  flex-direction: column; }
  .counter-input-holder .counter-header {
    font-size: 14px;
    font-weight: bold;
    color: #5a5a5a;
    margin-bottom: 5px; }
  .counter-input-holder .ant-form-item {
    display: flex !important; }
    .counter-input-holder .ant-form-item .ant-input-number {
      min-height: 55px;
      min-width: 100px; }
  .counter-input-holder .ant-input-number-input {
    height: 100%;
    padding-top: 18px;
    text-align: left; }
    body.rtl .counter-input-holder .ant-input-number-input {
      text-align: right; }
  .counter-input-holder .ant-input-number-input-wrap {
    height: 100%;
    font-size: 16px;
    color: #242424; }
  .counter-input-holder .ant-input-number-handler-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 30px;
    padding-bottom: 8px;
    top: auto;
    bottom: 0;
    right: 0;
    border: 0;
    opacity: 1 !important; }
    body.rtl .counter-input-holder .ant-input-number-handler-wrap {
      left: 0;
      right: auto; }
    .counter-input-holder .ant-input-number-handler-wrap span {
      position: relative;
      height: 16px !important;
      width: 16px;
      font-size: 16px;
      border: 0;
      margin-right: 8px !important;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #dadce0;
      border-radius: 2px; }
      .counter-input-holder .ant-input-number-handler-wrap span:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-up::after {
        content: "\002B"; }
      .counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-down::after {
        content: "\2212"; }
      .counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
        margin-right: 0; }
        body.rtl .counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
          margin-right: 20px;
          margin-left: 0; }
      .counter-input-holder .ant-input-number-handler-wrap span i {
        display: none !important; }

.ant-input {
  height: 44px;
  border: solid 1px #dadce0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0px 22px !important;
  font-size: 0.75rem;
  font-weight: 500;
  color: #242424; }
  .ant-input:-moz-placeholder {
    font-size: 0.75rem;
    color: #4b4b4b;
    font-weight: 500;
    text-transform: capitalize; }
  .ant-input::-moz-placeholder {
    font-size: 0.75rem;
    color: #4b4b4b;
    font-weight: 500;
    text-transform: capitalize; }
  .ant-input:-ms-input-placeholder {
    font-size: 0.75rem;
    color: #4b4b4b;
    font-weight: 500;
    text-transform: capitalize; }
  .ant-input::-webkit-input-placeholder {
    font-size: 0.75rem;
    color: #4b4b4b;
    font-weight: 500;
    text-transform: capitalize; }

.ant-input-prefix {
  display: none; }

.ant-form-item {
  position: relative;
  outline: none; }
  .ant-form-item .ant-form-item-control {
    outline: none; }

.ant-input-affix-wrapper,
.ant-form-item-control-wrapper {
  outline: none; }

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-width: 1px !important; }

.ant-input[disabled] {
  background-color: #f2f2f2; }

.ant-select .ant-select-selection {
  height: auto;
  border: solid 1px #dadce0;
  border-radius: 4px;
  background-color: transparent;
  padding: 10px 12px !important;
  padding-top: 15px !important;
  font-size: 0.75rem;
  font-weight: 500;
  color: #242424;
  box-shadow: none; }
  .ant-select .ant-select-selection .ant-select-selection__placeholder {
    font-size: 0.75rem;
    color: #4b4b4b;
    font-weight: 500;
    text-transform: capitalize; }

.ant-input-search {
  height: 35px; }
  .ant-input-search .ant-input {
    height: 35px;
    padding-top: 10px !important;
    background-color: rgba(255, 255, 255, 0.47); }
    .ant-input-search .ant-input:-moz-placeholder {
      font-size: 14px;
      color: #949494;
      text-transform: capitalize; }
    .ant-input-search .ant-input::-moz-placeholder {
      font-size: 14px;
      color: #949494;
      text-transform: capitalize; }
    .ant-input-search .ant-input:-ms-input-placeholder {
      font-size: 14px;
      color: #949494;
      text-transform: capitalize; }
    .ant-input-search .ant-input::-webkit-input-placeholder {
      font-size: 14px;
      color: #949494;
      text-transform: capitalize; }
  .ant-input-search .ant-input-suffix i {
    font-size: 18px;
    color: #001529; }

.ant-select-dropdown .ant-select-dropdown-menu {
  /* Track */
  /* Handle */ }
  .ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
    width: 8px; }
  .ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-track {
    background: #f0f0f0; }
  .ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 5px; }

.ant-tooltip .ant-tooltip-inner {
  min-height: 23px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000d9; }

.ant-pagination li:hover {
  border-color: #001529; }
  .ant-pagination li:hover a {
    color: #001529 !important;
    border-color: #001529 !important; }
.ant-pagination .ant-pagination-item-active {
  border-color: #001529; }
  .ant-pagination .ant-pagination-item-active a {
    color: #001529; }

.has-error .ant-form-explain, .has-error .ant-form-split {
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  bottom: -22px;
  left: 0px; }
  body.rtl .has-error .ant-form-explain,
  body.rtl .has-error .ant-form-split {
    left: auto;
    right: 0px; }

.ant-select-selection__placeholder {
  margin-top: -10px;
  text-transform: capitalize; }

.ant-select-selection-selected-value {
  margin-top: 0px; }

.ant-select-selection__rendered {
  margin-top: -8px; }

.ant-select-dropdown-menu-item {
  font-size: 0.75rem; }

form textarea.ant-input {
  padding-top: 12px !important; }

textarea {
  resize: none; }

.relative_holder {
  width: 47.5% !important; }
  @media only screen and (max-width: 480px) {
    .relative_holder {
      width: 100% !important; } }
  .relative_holder .ant-form-item {
    width: 100% !important; }

.header {
  background-color: #001529;
  padding: 10px 2.5%; }
  .header .lang-holder .secondary_fill {
    margin: 0 15px 0 0; }
    body.rtl .header .lang-holder .secondary_fill {
      margin: 0 0 0 15px; }
    .header .lang-holder .secondary_fill .login-form-forgot {
      color: #fff; }
  .header .lang-holder .white_fill .login-form-forgot {
    color: #bf9c51; }
  @media only screen and (max-width: 768px) {
    .header {
      padding: 10px 2%; } }
  .header img {
    margin-right: 80px; }
    @media only screen and (max-width: 768px) {
      .header img {
        margin-right: 30px; } }
    body.rtl .header img {
      margin-left: 100px;
      margin-right: 0px; }
  .header > div:first-of-type a {
    color: #fff;
    padding-right: 40px; }
    @media only screen and (max-width: 768px) {
      .header > div:first-of-type a {
        padding-right: 20px; } }
    body.rtl .header > div:first-of-type a {
      padding-right: 0px;
      padding-left: 40px; }
  .header .lang_style {
    background-color: #fff;
    margin-right: 20px; }
    body.rtl .header .lang_style {
      margin-right: 0px;
      margin-left: 20px; }

.top_fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #312782), to(#1f114c));
  background-image: linear-gradient(to bottom, #312782 8%, #1f114c); }

.react-tel-input input[type=text], .react-tel-input input[type=tel] {
  width: 100% !important;
  min-height: 44px;
  padding-left: 60px !important; }
  body.rtl .react-tel-input input[type=text],
  body.rtl .react-tel-input input[type=tel] {
    padding-left: 0 !important;
    padding-right: 60px; }

.react-tel-input {
  min-height: 44px; }
  .react-tel-input .flag-dropdown {
    min-width: 50px; }
  .react-tel-input .selected-flag {
    padding: 0 0 0 11px; }
    body.rtl .react-tel-input .selected-flag {
      padding: 0 11px 0 0px; }
    body.rtl .react-tel-input .selected-flag .arrow {
      left: auto;
      right: 20px; }

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important; }

.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0; }

.react-tel-input .flag-dropdown.open-dropdown {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0; }

.react-tel-input .selected-flag {
  z-index: 1 !important; }

header.user-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 55;
  border-bottom: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  min-height: 60px;
  padding-left: 35px;
  padding-right: calc( 14px + 20px ); }
  body.rtl header.user-header {
    padding-left: calc(14px + 20px);
    padding-right: 35px; }
  header.user-header p {
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #001529; }
  header.user-header .ant-input-search {
    width: 275px; }
    header.user-header .ant-input-search .ant-input {
      padding: 0 15px !important; }
    header.user-header .ant-input-search .ant-input-suffix {
      right: 15px; }
      body.rtl header.user-header .ant-input-search .ant-input-suffix {
        right: auto;
        left: 15px; }
    @media only screen and (max-width: 768px) {
      header.user-header .ant-input-search {
        width: 150px; } }
  header.user-header .notify-holder {
    margin: 0 20px;
    cursor: pointer;
    position: relative; }
    header.user-header .notify-holder .notify-count {
      box-sizing: content-box;
      position: absolute;
      top: -6px;
      right: -6px;
      width: 15px;
      height: 15px;
      border-radius: 12px;
      border: solid 1.5px #f5f5f5;
      background-color: #d83025;
      font-size: 10px;
      font-weight: bold;
      color: #ffffff; }
      body.rtl header.user-header .notify-holder .notify-count {
        left: -6px;
        right: auto; }
  header.user-header .profile-holder {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid #e5e5e5;
    box-sizing: content-box;
    cursor: pointer; }
    header.user-header .profile-holder::after {
      content: "\2193";
      font-size: 10px;
      font-weight: 400;
      width: 20px;
      height: 17px;
      border-radius: 0 50% 50% 0;
      background-color: #e5e5e5;
      position: absolute;
      top: 50%;
      right: -20px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      body.rtl header.user-header .profile-holder::after {
        right: auto;
        left: -20px;
        border-radius: 50% 0 0 50%; }
    header.user-header .profile-holder img {
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  header.user-header .lang-btn {
    font-size: 16px;
    color: #1f883c;
    margin: 0 15px;
    cursor: pointer; }
  header.user-header .ant-divider {
    height: 35px;
    margin: 0; }
header.grid-action-header {
  height: 44px;
  background-color: #f5f5f5;
  padding-left: 26px;
  padding-right: 14px; }
  body.rtl header.grid-action-header {
    padding-right: 26px;
    padding-left: 14px; }
  header.grid-action-header .action-btns {
    margin-left: 20px; }
    header.grid-action-header .action-btns > span {
      display: inline-flex;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
      width: 18px;
      height: 18px;
      cursor: pointer; }
      header.grid-action-header .action-btns > span:last-of-type {
        margin-right: 0; }
        body.rtl header.grid-action-header .action-btns > span:last-of-type {
          margin-left: 0; }
      header.grid-action-header .action-btns > span.duplicate-btn {
        background-image: url(../../assets/duplicate-icon.svg); }
        header.grid-action-header .action-btns > span.duplicate-btn[disabled] {
          background-image: url(../../assets/duplicate-icon-active.svg); }
      header.grid-action-header .action-btns > span.delete-btn {
        background-image: url(../../assets/delete-icon.svg); }
        header.grid-action-header .action-btns > span.delete-btn[disabled] {
          background-image: url(../../assets/delete-icon-active.svg); }
      header.grid-action-header .action-btns > span.archive-btn {
        background-image: url(../../assets/archive-icon.svg); }
        header.grid-action-header .action-btns > span.archive-btn[disabled] {
          background-image: url(../../assets/archive-icon-active.svg); }
      header.grid-action-header .action-btns > span.edit-btn {
        background-image: url(../../assets/edit-icon.svg); }
        header.grid-action-header .action-btns > span.edit-btn[disabled] {
          background-image: url(../../assets/edit-icon-active.svg); }
      body.rtl header.grid-action-header .action-btns > span {
        margin-left: 0px;
        margin-right: 20px; }

.user-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d6d6d6;
  min-width: 170px; }
  .user-menu-wrapper > li {
    min-height: 40px;
    display: flex;
    align-items: center; }
    .user-menu-wrapper > li a {
      margin: 0;
      padding: 0;
      font-size: 13px;
      font-weight: bold;
      color: #565656; }
    .user-menu-wrapper > li:first-of-type {
      padding: 16px 14px 12px 14px; }
      .user-menu-wrapper > li:first-of-type h6 {
        font-size: 15px;
        font-weight: bold;
        color: #001529; }
      .user-menu-wrapper > li:first-of-type span {
        font-size: 9px;
        font-weight: bold;
        color: #565656;
        letter-spacing: 0.18px; }
    .user-menu-wrapper > li:hover {
      background-color: #f0f3f8; }

.notify-menu-wrapper {
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d6d6d6; }
  .notify-menu-wrapper header {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 18px;
    color: #ffffff;
    background-color: #001529; }
  .notify-menu-wrapper > li:not(:last-of-type) {
    min-height: 85px;
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 30px;
    background-color: rgba(57, 103, 169, 0.09) !important;
    border-bottom: 1px solid #d6d6d6; }
    .notify-menu-wrapper > li:not(:last-of-type) a {
      padding: 0;
      margin: 0;
      position: relative; }
      .notify-menu-wrapper > li:not(:last-of-type) a h6 {
        font-size: 14px;
        font-weight: bold;
        color: #242424; }
      .notify-menu-wrapper > li:not(:last-of-type) a span {
        font-size: 10px;
        font-weight: bold;
        color: #565656; }
      .notify-menu-wrapper > li:not(:last-of-type) a::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #001529;
        border-radius: 50%;
        position: absolute;
        left: -20px; }
        body.rtl .notify-menu-wrapper > li:not(:last-of-type) a::before {
          left: auto;
          right: -20px; }
    .notify-menu-wrapper > li:not(:last-of-type).seen {
      background-color: #ffffff !important; }
      .notify-menu-wrapper > li:not(:last-of-type).seen a::before {
        display: none; }
    body.rtl .notify-menu-wrapper > li:not(:last-of-type) {
      padding: 12px 30px 12px 12px; }
  .notify-menu-wrapper > li:last-of-type {
    min-height: 40px; }
    .notify-menu-wrapper > li:last-of-type a {
      padding: 0;
      margin: 0; }
      .notify-menu-wrapper > li:last-of-type a span {
        font-size: 12px;
        font-weight: bold;
        color: #565656; }
    .notify-menu-wrapper > li:last-of-type:hover {
      background-color: transparent; }

.logout_modal {
  text-align: center; }
  .logout_modal p {
    margin: 30px 0; }
  .logout_modal .btn_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .logout_modal .btn_holder button {
      width: 150px;
      margin: 0 0 20px 0; }

aside.dashboard-menu {
  overflow: visible !important; }
  aside.dashboard-menu .ant-menu {
    background: transparent !important;
    box-shadow: none !important; }
    aside.dashboard-menu .ant-menu > li {
      border-bottom: 1px solid #fff;
      background: transparent;
      color: #ffffff;
      min-height: 57px;
      margin-bottom: 0 !important; }
      aside.dashboard-menu .ant-menu > li a {
        color: #ffffff; }
      aside.dashboard-menu .ant-menu > li:last-of-type {
        border-bottom: none; }
    aside.dashboard-menu .ant-menu .ant-menu-item {
      margin-top: 0;
      line-height: 57px; }
    aside.dashboard-menu .ant-menu.ant-menu-vertical > li {
      color: #fff; }
aside .trigger {
  position: absolute;
  z-index: 99;
  left: 200px;
  top: -41px;
  font-size: 25px; }

.ant-layout-sider-children {
  min-height: 100vh;
  overflow: auto; }
  .ant-layout-sider-children img {
    margin: 0 20px 0 0; }
    body.rtl .ant-layout-sider-children img {
      margin: 0 0 0 20px; }

@media only screen and (max-width: 640px) {
  .ant-layout-sider {
    max-width: 58px !important;
    min-width: 58px !important;
    width: 58px !important; } }

.auth_bg_holder {
  height: 100vh; }
  .auth_bg_holder .center_div {
    align-items: center;
    display: flex; }

.time_out_page {
  max-width: 450px;
  padding: 50px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background-color: #312782; }
  .time_out_page p {
    color: #fff;
    line-height: 1.5; }

.admin-modal-wrapper .ant-modal {
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  padding: 0;
  margin: 0; }
.admin-modal-wrapper .ant-modal-content {
  height: 100%;
  width: 100%; }
  .admin-modal-wrapper .ant-modal-content .ant-modal-close-x {
    width: 50px;
    height: 50px;
    font-size: 25px;
    background-color: #c30c00;
    color: #fff; }
  .admin-modal-wrapper .ant-modal-content .ant-modal-header {
    height: 50px;
    background-color: #f4f4f4;
    border-radius: 0; }
    .admin-modal-wrapper .ant-modal-content .ant-modal-header .ant-modal-title {
      font-size: 1.125rem;
      font-weight: bold;
      color: #242424; }
  .admin-modal-wrapper .ant-modal-content .ant-modal-body {
    border-radius: 0;
    height: calc(100% - 50px - 90px);
    overflow: auto;
    padding: 0; }
  .admin-modal-wrapper .ant-modal-content .ant-modal-footer {
    height: 90px;
    justify-content: flex-end;
    background-color: #ededed;
    border-radius: 0; }
.admin-modal-wrapper .ant-form {
  height: 100%; }
.admin-modal-wrapper .Personal-info-wrapper {
  height: auto;
  overflow: auto; }
  .admin-modal-wrapper .Personal-info-wrapper .ant-collapse {
    background-color: transparent;
    border: 0; }
    .admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header {
      border: 0;
      box-shadow: none;
      background: transparent;
      font-size: 11px;
      font-weight: bold;
      color: #1f883c; }
      .admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-item {
        border: 0; }
    .admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-item {
      border: 0; }
    .admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content {
      border: 0px; }
    .admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content-box {
      padding: 0; }
.admin-modal-wrapper .rules-wrapper {
  background-color: #f8f8f8;
  height: 100%; }
  .admin-modal-wrapper .rules-wrapper h6 {
    color: #1f883c;
    padding-bottom: 10px; }
  .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered {
    width: 100%;
    height: 265px;
    overflow-y: auto;
    border-radius: 4px;
    border: solid 2px #dadce0;
    background-color: #ffffff;
    margin: 0;
    position: absolute;
    left: 0;
    top: 108px;
    /* Track */
    /* Handle */ }
    body.rtl .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered {
      overflow-x: hidden;
      left: auto;
      right: 0; }
    .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered::-webkit-scrollbar {
      width: 8px; }
    .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered::-webkit-scrollbar-track {
      background: #f0f0f0; }
    .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered::-webkit-scrollbar-thumb {
      background: #747474;
      border-radius: 5px; }
    .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li {
        max-width: 100%;
        margin: 0;
        border-radius: 0;
        border: 0;
        height: 40px;
        background-color: transparent;
        color: #404040;
        font-size: 13px; }
        .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li:nth-child(even) {
          background-color: #f7f7f7; }
        .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li:last-of-type {
          background: transparent;
          height: 1px; }
        .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li .ant-select-selection__choice__remove {
          font-size: 16px; }
          body.rtl .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li .ant-select-selection__choice__remove {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            left: 5px;
            right: auto; }
    .admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
      width: 100%;
      top: 25px;
      text-align: center;
      margin: 0; }

.modal_footer {
  text-align: right; }
  .modal_footer .Primary_fill {
    margin: 0 0 0 20px !important; }
    body.rtl .modal_footer .Primary_fill {
      margin: 0 20px 0 0 !important; }
  body.rtl .modal_footer {
    text-align: left !important; }

.ant-layout-footer {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #312782), to(#1f114c)) !important;
  background-image: linear-gradient(to bottom, #312782 8%, #1f114c) !important;
  color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 200px;
  text-align: center;
  z-index: 3; }
  body.rtl .ant-layout-footer {
    left: 0;
    right: 200px; }
  @media only screen and (max-width: 640px) {
    .ant-layout-footer {
      left: 58px !important; } }

.login_holder {
  flex-direction: column; }
  .login_holder .logo {
    margin-bottom: 50px; }
  .login_holder form {
    width: 35%;
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 768px) {
      .login_holder form {
        width: 80%; } }

.add_clone_btn_holder {
  text-align: right; }
  body.rtl .add_clone_btn_holder {
    text-align: left; }

.login {
  position: absolute;
  top: 80px;
  left: 100px;
  font-size: 1.875rem;
  color: #000;
  padding: 0 0 5px 0;
  font-weight: normal; }
  .login::before {
    content: " ";
    width: 116px;
    height: 3px;
    background: #bf9c51;
    position: absolute;
    bottom: -3px;
    left: 3px; }
    body.rtl .login::before {
      right: 3px;
      left: auto; }
  body.rtl .login {
    right: 100px;
    left: auto; }

.login_by_mobile .ant-form-item {
  margin: 0 0 35px 0; }
.login_by_mobile form .ant-row {
  width: 100%; }
.login_by_mobile .login_btn {
  background-color: #001529;
  min-height: 44.7px;
  min-width: 57px;
  border: 1px solid #48447f; }
  .login_by_mobile .login_btn span {
    color: #fff; }
  body.rtl .login_by_mobile .login_btn {
    border-radius: 4px 0px 0px 4px;
    margin-left: 0; }

.login_by_password .Primary_fill {
  margin: 10px 0; }
.login_by_password .forget_password {
  text-decoration: underline;
  color: #000;
  font-weight: bold;
  font-size: 0.9375rem;
  cursor: pointer; }

.sign_up form {
  width: 60%; }
  @media only screen and (max-width: 768px) {
    .sign_up form {
      width: 90%; } }
  .sign_up form .ant-form-item {
    width: 47.5%;
    margin-bottom: 30px; }
    @media only screen and (max-width: 480px) {
      .sign_up form .ant-form-item {
        width: 100%; } }

.holder_100 .ant-form-item {
  width: 100% !important; }

.termsCondation {
  padding-left: 15px; }
  body.rtl .termsCondation {
    padding-left: 0px;
    padding-right: 15px; }

.verify_container {
  max-width: 350px;
  border-radius: 6px;
  border: solid 0.5px #9f9f9f;
  margin: auto; }

.verification-input__character {
  font-size: 25px; }

.ant-modal-close-x {
  display: none; }

.reset_password .has-error .ant-form-explain,
.reset_password .has-error .ant-form-split {
  position: relative !important;
  bottom: 0; }

.custom_counter {
  display: flex;
  align-items: center;
  justify-content: center; }
  .custom_counter .contant_holder {
    flex-direction: initial !important;
    height: 10px !important; }
  .custom_counter .timer_holder svg .static_circle {
    display: none; }
  .custom_counter .timer_holder svg .dinamic_circle {
    stroke: transparent; }
  .custom_counter .timer_holder #countdown-number {
    height: 59px;
    margin: 0 5px 0 0; }

.member_Acount form .ant-form-item {
  width: 70%;
  margin-bottom: 30px !important;
  margin: auto; }

.change_btns_holder {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center; }
  .change_btns_holder a {
    color: #000;
    font-weight: 600;
    text-transform: capitalize; }
  .change_btns_holder button {
    background-color: transparent; }
    .change_btns_holder button span {
      color: #000;
      font-weight: 600;
      text-transform: capitalize; }

.verify_forget .ant-modal-body {
  padding: 40px 0; }
.verify_forget h3 {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px; }
.verify_forget .ant-form-item {
  width: 100%; }

.colors_action_holder .delete {
  background-color: #d42727;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  margin: 0 10px 0 0;
  border-radius: 3px;
  text-transform: capitalize; }
.colors_action_holder .edit {
  background-color: #000;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  margin: 0 10px 0 0;
  border-radius: 3px;
  text-transform: capitalize; }

.span_code_color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%; }

.color_select_holder {
  display: flex;
  align-items: center; }
  .color_select_holder .span_code_color {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 15px 0 0; }
    body.rtl .color_select_holder .span_code_color {
      margin: 0 0 0 15px; }

.order_title {
  display: flex;
  align-items: center; }
  .order_title img {
    margin: 0 20px 0 0; }
    body.rtl .order_title img {
      margin: 0 0 0 20px; }

.date_created {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0; }
  .date_created span {
    color: #000; }
  .date_created .timee {
    margin: 0 10px !important;
    display: inline-block; }

.address_holder .span_code_color {
  margin: 0 10px; }
.address_holder strong {
  font-weight: bolder !important;
  text-transform: capitalize; }
.address_holder .black_text {
  color: #000;
  font-weight: 600;
  margin: 0 0 0 5px; }
  body.rtl .address_holder .black_text {
    margin: 0 5px 0 0; }

.ads_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
  z-index: 2; }
  .ads_header .login {
    position: relative;
    top: auto;
    left: auto; }
    @media only screen and (max-width: 640px) {
      .ads_header .login {
        display: none !important; } }

.delete_message .ant-modal {
  max-width: 550px; }

.ad_holder {
  padding: 50px 40px;
  margin: 0 0 50px 0; }
  .ad_holder .ad_name {
    font-size: 0.9375rem;
    color: #000;
    margin: 0 0 10px 0; }
  .ad_holder .gutter-example .ant-row > div {
    background: transparent;
    border: 0; }
  .ad_holder .gutter-box {
    padding: 10px 20px;
    border: 1px solid #9f9f9f; }
  .ad_holder .duration_holder {
    margin: 10px 0 10px 0; }
    .ad_holder .duration_holder > span {
      margin: 0 30px 0 0; }
      body.rtl .ad_holder .duration_holder > span {
        margin: 0 0 0 30px; }
    .ad_holder .duration_holder span {
      color: #000; }
  .ad_holder .branch_name_holder span {
    color: #000;
    padding: 0 10px 0 0; }

.time {
  margin: 0 10px 0 0; }
  body.rtl .time {
    margin: 0 0 0 10px; }

.upload_ads_holder {
  padding: 0 0 25px 0; }
  .upload_ads_holder .ant-upload-picture-card-wrapper {
    display: flex; }

.ads_date_holder .end .ant-form-item-control {
  text-align: right; }
  body.rtl .ads_date_holder .end .ant-form-item-control {
    text-align: left !important; }

.admin_account .ant-form-item {
  width: auto !important; }

.packages_holder {
  margin: 30px 0 30px 0; }

.one_package {
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: inline-block;
  width: 170px;
  margin: 0 20px 20px 0; }
  body.rtl .one_package {
    margin: 0 0px 0 20px; }
  .one_package .top {
    padding: 20px;
    text-align: center;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.14);
    border-radius: 14px 14px 0 0; }
    .one_package .top h3:first-of-type {
      color: #001529;
      text-transform: capitalize; }
  .one_package .mid {
    padding: 20px; }
    .one_package .mid div {
      margin: 0 0 10px 0; }
      .one_package .mid div span {
        color: #001529; }
  .one_package .bottom {
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.14);
    background-color: #f6f6f6;
    border-radius: 0px 0px 14px 14px; }
    .one_package .bottom button {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: #bf9c51;
      color: #fff;
      display: flex;
      justify-content: center;
      margin: auto; }

.selected_package {
  background-color: #312782;
  color: #fff; }
  .selected_package h3 {
    color: #fff !important;
    font-weight: bold; }
  .selected_package .top {
    box-shadow: none; }
  .selected_package span {
    color: #fff !important; }
  .selected_package .bottom {
    background-color: #48447f; }
    .selected_package .bottom button {
      background-color: #fff;
      color: #48447f; }

.branch_holder {
  border: solid 0.5px rgba(159, 159, 159, 0.5);
  padding: 10px;
  margin: 0 0 24px 0; }
  .branch_holder > .ant-row-flex:first-of-type {
    margin: 0 0 20px 0; }
    .branch_holder > .ant-row-flex:first-of-type > div:nth-child(2) {
      padding-left: 15px; }
      body.rtl .branch_holder > .ant-row-flex:first-of-type > div:nth-child(2) {
        padding-left: 0px;
        padding-right: 15px; }
      .branch_holder > .ant-row-flex:first-of-type > div:nth-child(2) p {
        padding-bottom: 3px;
        color: #000; }
  .branch_holder > .ant-row-flex:nth-child(2) div button {
    cursor: pointer; }
  .branch_holder > .ant-row-flex:nth-child(2) div img {
    margin: 0 10px 0 0px; }
    body.rtl .branch_holder > .ant-row-flex:nth-child(2) div img {
      margin: 0 0 0 10px; }
  .branch_holder > .ant-row-flex:nth-child(2) div strong {
    color: #001529; }

.add_btn_holder {
  padding: 20px 10px;
  text-align: right; }
  body.rtl .add_btn_holder {
    text-align: left; }

.add-btn img {
  margin: 0 14px 0 0; }
  body.rtl .add-btn img {
    margin: 0 0px 0 14px; }

.branches_popup p {
  color: #000; }
.branches_popup h2 {
  margin: 25px 0; }

.holder-50 .ant-form-item {
  width: 47.5%; }

.empty_service {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 20px 0 20px; }
  .empty_service p {
    color: #000;
    padding: 0 0 20px 0; }

.container_holder {
  padding: 35px; }

.isDeleted {
  position: absolute;
  right: 16px;
  display: inline-block;
  background: rgba(205, 21, 24, 0.35);
  padding: 6px 20px;
  top: 8px;
  border-radius: 5px; }
  body.rtl .isDeleted {
    right: auto;
    left: 16px; }

.category_holder {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px;
  background-color: #fff !important;
  text-transform: capitalize;
  margin: 0 0 30px 0;
  max-height: 250px;
  height: 250px; }
  .category_holder h1 {
    font-size: 14px;
    margin: 0 0 10px 0;
    color: #000;
    font-weight: 600;
    text-transform: capitalize; }
  .category_holder img {
    max-width: 200px;
    margin: 20px 0px;
    max-height: 100px; }

.RichTextEditor__root___2QXK- {
  max-width: 90%; }

.ButtonGroup__root___3lEAn > div:last-child > button {
  display: none; }

.EditorToolbar__root___3_Aqz div:last-child {
  display: none; }

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn:first-child {
  display: none; }

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn:nth-child(2) {
  display: none; }

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn:nth-child(4) {
  display: none; }

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn:nth-child(5) {
  display: none; }

.EditorToolbar__root___3_Aqz .ButtonGroup__root___3lEAn:nth-child(6) {
  display: none; }

.category_btn_holder {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .category_btn_holder i {
    cursor: pointer;
    font-size: 20px; }
  .category_btn_holder .anticon-edit {
    margin: 0 15px 0 0; }
    body.rtl .category_btn_holder .anticon-edit {
      margin: 0 0 0 15px; }

.category_holder_custom {
  max-height: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.ant-modal {
  width: 70% !important; }

.right_div .ant-form-item {
  width: 90%; }

.left_div .ant-form-item {
  width: 90%; }

.ant-upload-picture-card-wrapper {
  width: auto; }

.contact_us {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .contact_us div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0; }

.address_holder {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  padding: 20px 20px 13px 20px !important;
  border-radius: 10px;
  margin: 0 15px 15px 0; }
  .address_holder h5 {
    font-weight: 600;
    margin: 0 0 7px 0; }
  .address_holder p {
    color: #5d6270;
    font-size: 13px;
    margin: 0 0 5px 0;
    font-weight: 500; }

@media only screen and (max-width: 992px) {
  body,
  html {
    font-size: 15px !important; } }
@media only screen and (max-width: 768px) {
  body,
  html {
    font-size: 14px !important; } }
.rtl {
  direction: rtl; }
  .rtl dd {
    margin-right: 0; }
  .rtl caption {
    text-align: right; }
  .rtl .move-left-enter,
  .rtl .move-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .move-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .move-left-enter.move-left-enter-active,
  .rtl .move-left-appear.move-left-appear-active {
    -webkit-animation-name: antMoveleftIn;
    animation-name: antMoveleftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .move-left-leave.move-left-leave-active {
    -webkit-animation-name: antMoveleftOut;
    animation-name: antMoveleftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .move-left-enter,
  .rtl .move-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .rtl .move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }
  .rtl .move-right-enter,
  .rtl .move-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .move-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .move-right-enter.move-right-enter-active,
  .rtl .move-right-appear.move-right-appear-active {
    -webkit-animation-name: antMoverightIn;
    animation-name: antMoverightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .move-right-leave.move-right-leave-active {
    -webkit-animation-name: antMoverightOut;
    animation-name: antMoverightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .move-right-enter,
  .rtl .move-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .rtl .move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }
@-webkit-keyframes antMoveleftIn {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; } }
@keyframes antMoveleftIn {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; } }
@-webkit-keyframes antMoveleftOut {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }
@keyframes antMoveleftOut {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }
@-webkit-keyframes antMoverightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }
@keyframes antMoverightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }
@-webkit-keyframes antMoverightOut {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }
@keyframes antMoverightOut {
  0% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1; }
  100% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }
  .rtl .slide-left-enter,
  .rtl .slide-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .slide-left-enter.slide-left-enter-active,
  .rtl .slide-left-appear.slide-left-appear-active {
    -webkit-animation-name: antSlideleftIn;
    animation-name: antSlideleftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .slide-left-leave.slide-left-leave-active {
    -webkit-animation-name: antSlideleftOut;
    animation-name: antSlideleftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .slide-left-enter,
  .rtl .slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .rtl .slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  .rtl .slide-right-enter,
  .rtl .slide-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .slide-right-enter.slide-right-enter-active,
  .rtl .slide-right-appear.slide-right-appear-active {
    -webkit-animation-name: antSliderightIn;
    animation-name: antSliderightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .slide-right-leave.slide-right-leave-active {
    -webkit-animation-name: antSliderightOut;
    animation-name: antSliderightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .slide-right-enter,
  .rtl .slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .rtl .slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
@-webkit-keyframes antSlideleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }
@keyframes antSlideleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }
@-webkit-keyframes antSlideleftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }
@keyframes antSlideleftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }
@-webkit-keyframes antSliderightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }
@keyframes antSliderightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }
@-webkit-keyframes antSliderightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }
@keyframes antSliderightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }
  .rtl .zoom-left-enter,
  .rtl .zoom-left-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .zoom-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .zoom-left-enter.zoom-left-enter-active,
  .rtl .zoom-left-appear.zoom-left-appear-active {
    -webkit-animation-name: antZoomleftIn;
    animation-name: antZoomleftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .zoom-left-leave.zoom-left-leave-active {
    -webkit-animation-name: antZoomleftOut;
    animation-name: antZoomleftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .zoom-left-enter,
  .rtl .zoom-left-appear {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .rtl .zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .rtl .zoom-right-enter,
  .rtl .zoom-right-appear {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .zoom-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .rtl .zoom-right-enter.zoom-right-enter-active,
  .rtl .zoom-right-appear.zoom-right-appear-active {
    -webkit-animation-name: antZoomrightIn;
    animation-name: antZoomrightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .rtl .zoom-right-leave.zoom-right-leave-active {
    -webkit-animation-name: antZoomrightOut;
    animation-name: antZoomrightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .rtl .zoom-right-enter,
  .rtl .zoom-right-appear {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .rtl .zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
@-webkit-keyframes antZoomleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes antZoomleftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }
@-webkit-keyframes antZoomleftOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }
@keyframes antZoomleftOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }
@-webkit-keyframes antZoomrightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes antZoomrightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }
@-webkit-keyframes antZoomrightOut {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }
@keyframes antZoomrightOut {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }
  .rtl .ant-alert-icon {
    top: 12.5px;
    right: 16px;
    position: absolute; }
  .rtl .ant-alert-close-icon {
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 8px;
    line-height: 22px;
    overflow: hidden;
    cursor: pointer; }
  .rtl .ant-alert-close-text {
    position: absolute;
    left: 16px; }
  .rtl .ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 16px;
    right: 24px;
    font-size: 24px; }
  .rtl .ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
    font-size: 14px; }
  .rtl .ant-anchor {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    padding-right: 2px; }
  .rtl .ant-anchor-wrapper {
    background-color: #fff;
    overflow: auto;
    padding-right: 4px;
    margin-right: -4px; }
  .rtl .ant-anchor-ink {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0; }
  .rtl .ant-anchor-ink-ball {
    right: 50%; }
  .rtl .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    margin-right: 0;
    margin-left: 0; }
  .rtl .ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: auto; }
  .rtl .ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: static;
    float: right; }
  .rtl .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-left: 0 !important; }
  .rtl .ant-select-arrow {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 11px;
    right: auto;
    line-height: 1;
    margin-top: -6px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px; }
  .rtl .ant-select-selection__clear {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    opacity: 0;
    position: absolute;
    left: 11px;
    z-index: 1;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease; }
  .rtl .ant-select-selection-selected-value {
    float: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding-left: 20px; }
  .rtl .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    background: #f5f5f5;
    color: #aaa;
    padding-left: 10px; }
  .rtl .ant-select-selection__rendered {
    margin-right: 11px;
    margin-left: 11px; }
  .rtl .ant-select-sm .ant-select-selection__clear,
  .rtl .ant-select-sm .ant-select-arrow {
    left: 8px; }
  .rtl .ant-select-selection__placeholder,
  .rtl .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    right: 0;
    left: 9px;
    color: #bfbfbf;
    line-height: 20px;
    height: 20px;
    max-width: 100%;
    margin-top: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right; }
  .rtl .ant-select-search__field__placeholder {
    right: 12px; }
  .rtl .ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    right: -9999px;
    white-space: pre;
    pointer-events: none; }
  .rtl .ant-select-search--inline > i {
    float: left; }
  .rtl .ant-select-selection--multiple .ant-select-search--inline {
    float: right;
    position: static;
    width: auto;
    padding: 0;
    max-width: 100%; }
  .rtl .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-right: 5px; }
  .rtl .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-right: 6px; }
  .rtl .ant-select-selection--multiple .ant-select-selection__choice {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    float: right;
    margin-left: 4px;
    max-width: 99%;
    position: relative;
    overflow: hidden;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 10px 0 20px; }
  .rtl .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    line-height: inherit;
    cursor: pointer;
    font-weight: bold;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    position: absolute;
    left: 4px;
    right: auto; }
  .rtl .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-left: 20px; }
  .rtl .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
    margin-left: 20px; }
  .rtl .ant-select-dropdown {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    direction: rtl;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1050;
    top: -9999px;
    position: absolute;
    outline: none;
    font-size: 14px; }
  .rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomleft,
  .rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomleft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topleft,
  .rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topleft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomleft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topleft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .rtl .ant-select-dropdown-menu {
    outline: none;
    margin-bottom: 0;
    padding-right: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto; }
  .rtl .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-right: 20px; }
  .rtl .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\E632";
    color: transparent;
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 12px;
    font-weight: bold;
    text-shadow: 0 0.1px 0, -0.1px 0 0, 0 -0.1px 0, 0.1px 0; }
  .rtl .ant-input-group[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0; }
  .rtl .ant-input-group > [class*="col-"] {
    padding-left: 8px; }
  .rtl .ant-input-group > [class*="col-"]:last-child {
    padding-left: 0; }
  .rtl .ant-input-group .ant-input {
    float: right;
    width: 100%;
    margin-bottom: 0; }
  .rtl .ant-input-group-addon > i:only-child:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0; }
  .rtl .ant-input-group > .ant-input:first-child,
  .rtl .ant-input-group-addon:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .rtl .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
  .rtl .ant-input-group-addon:first-child .ant-select .ant-select-selection {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .rtl .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .rtl .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .rtl .ant-input-group-addon:first-child {
    border-left: 0; }
  .rtl .ant-input-group-addon:last-child {
    border-right: 0; }
  .rtl .ant-input-group > .ant-input:last-child,
  .rtl .ant-input-group-addon:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .rtl .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
  .rtl .ant-input-group-addon:last-child .ant-select .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .rtl .ant-input-group .ant-input-affix-wrapper {
    display: table-cell;
    width: 100%;
    float: right; }
  .rtl .ant-input-group.ant-input-group-compact > * {
    border-radius: 0;
    border-left-width: 0;
    vertical-align: top;
    float: none;
    display: inline-block; }
  .rtl .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
  .rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .rtl .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-radius: 0;
    border-left-width: 0; }
  .rtl .ant-input-group.ant-input-group-compact > *:first-child,
  .rtl .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
  .rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .rtl .has-success.has-feedback .ant-form-item-children-icon, .rtl .has-warning.has-feedback .ant-form-item-children-icon, .rtl .has-error.has-feedback .ant-form-item-children-icon, .rtl .is-validating.has-feedback .ant-form-item-children-icon {
    right: auto;
    left: 0; }
  .rtl .ant-input-group.ant-input-group-compact > *:last-child,
  .rtl .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
  .rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px; }
  .rtl .ant-form-item-control.has-error .ant-form-explain {
    text-align: right; }
  .rtl .ant-input-affix-wrapper .ant-input-prefix {
    right: 12px; }
  .rtl .ant-input-affix-wrapper .ant-input-suffix {
    left: 12px;
    right: auto; }
  .rtl .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-right: 30px; }
  .rtl .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-left: 30px; }
  .rtl .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
    left: 12px; }
  .rtl .ant-input-search > .ant-input-suffix > .ant-input-search-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rtl .ant-input-search.ant-input-search-enter-button > .ant-input {
    padding-left: 46px; }
  .rtl .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
    left: 0; }
  .rtl .ant-btn > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn:hover > a:only-child:after,
  .rtl .ant-btn:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn:active > a:only-child:after,
  .rtl .ant-btn.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn.disabled > a:only-child:after,
  .rtl .ant-btn[disabled] > a:only-child:after,
  .rtl .ant-btn.disabled:hover > a:only-child:after,
  .rtl .ant-btn[disabled]:hover > a:only-child:after,
  .rtl .ant-btn.disabled:focus > a:only-child:after,
  .rtl .ant-btn[disabled]:focus > a:only-child:after,
  .rtl .ant-btn.disabled:active > a:only-child:after,
  .rtl .ant-btn[disabled]:active > a:only-child:after,
  .rtl .ant-btn.disabled.active > a:only-child:after,
  .rtl .ant-btn[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-primary > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-primary:hover > a:only-child:after,
  .rtl .ant-btn-primary:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-primary:active > a:only-child:after,
  .rtl .ant-btn-primary.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-primary.disabled > a:only-child:after,
  .rtl .ant-btn-primary[disabled] > a:only-child:after,
  .rtl .ant-btn-primary.disabled:hover > a:only-child:after,
  .rtl .ant-btn-primary[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-primary.disabled:focus > a:only-child:after,
  .rtl .ant-btn-primary[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-primary.disabled:active > a:only-child:after,
  .rtl .ant-btn-primary[disabled]:active > a:only-child:after,
  .rtl .ant-btn-primary.disabled.active > a:only-child:after,
  .rtl .ant-btn-primary[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-left-color: #40a9ff;
    border-right-color: #40a9ff; }
  .rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9; }
  .rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-left-color: #40a9ff; }
  .rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-left-color: #d9d9d9; }
  .rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: #40a9ff; }
  .rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #d9d9d9; }
  .rtl .ant-btn-ghost > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-ghost:hover > a:only-child:after,
  .rtl .ant-btn-ghost:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-ghost:active > a:only-child:after,
  .rtl .ant-btn-ghost.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-ghost.disabled > a:only-child:after,
  .rtl .ant-btn-ghost[disabled] > a:only-child:after,
  .rtl .ant-btn-ghost.disabled:hover > a:only-child:after,
  .rtl .ant-btn-ghost[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-ghost.disabled:focus > a:only-child:after,
  .rtl .ant-btn-ghost[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-ghost.disabled:active > a:only-child:after,
  .rtl .ant-btn-ghost[disabled]:active > a:only-child:after,
  .rtl .ant-btn-ghost.disabled.active > a:only-child:after,
  .rtl .ant-btn-ghost[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-dashed > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-dashed:hover > a:only-child:after,
  .rtl .ant-btn-dashed:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-dashed:active > a:only-child:after,
  .rtl .ant-btn-dashed.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-dashed.disabled > a:only-child:after,
  .rtl .ant-btn-dashed[disabled] > a:only-child:after,
  .rtl .ant-btn-dashed.disabled:hover > a:only-child:after,
  .rtl .ant-btn-dashed[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-dashed.disabled:focus > a:only-child:after,
  .rtl .ant-btn-dashed[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-dashed.disabled:active > a:only-child:after,
  .rtl .ant-btn-dashed[disabled]:active > a:only-child:after,
  .rtl .ant-btn-dashed.disabled.active > a:only-child:after,
  .rtl .ant-btn-dashed[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-danger > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-danger:hover > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-danger:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-danger:active > a:only-child:after,
  .rtl .ant-btn-danger.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-danger.disabled > a:only-child:after,
  .rtl .ant-btn-danger[disabled] > a:only-child:after,
  .rtl .ant-btn-danger.disabled:hover > a:only-child:after,
  .rtl .ant-btn-danger[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-danger.disabled:focus > a:only-child:after,
  .rtl .ant-btn-danger[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-danger.disabled:active > a:only-child:after,
  .rtl .ant-btn-danger[disabled]:active > a:only-child:after,
  .rtl .ant-btn-danger.disabled.active > a:only-child:after,
  .rtl .ant-btn-danger[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background: #fff;
    opacity: 0.35;
    content: '';
    border-radius: inherit;
    z-index: 1;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    pointer-events: none;
    display: none; }
  .rtl .ant-btn .anticon {
    -webkit-transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .rtl .ant-btn.ant-btn-loading:before {
    display: block; }
  .rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-right: 29px;
    pointer-events: none;
    position: relative; }
  .rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-right: -14px; }
  .rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-right: 24px; }
  .rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
    margin-right: -17px; }
  .rtl .ant-btn-group .ant-btn + .ant-btn,
  .rtl .ant-btn + .ant-btn-group,
  .rtl .ant-btn-group span + .ant-btn,
  .rtl .ant-btn-group .ant-btn + span,
  .rtl .ant-btn-group + .ant-btn,
  .rtl .ant-btn-group + .ant-btn-group {
    margin-right: -1px; }
  .rtl .ant-btn-group > .ant-btn:first-child,
  .rtl .ant-btn-group > span:first-child > .ant-btn {
    margin-right: 0; }
  .rtl .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .rtl .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .rtl .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .rtl .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .rtl .ant-btn-group > .ant-btn-group {
    float: right; }
  .rtl .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px; }
  .rtl .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px; }
  .rtl .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-btn:focus > span,
  .rtl .ant-btn:active > span {
    position: relative; }
  .rtl .ant-btn > .anticon + span,
  .rtl .ant-btn > span + .anticon {
    margin-right: 8px; }
  .rtl .ant-btn-clicked:after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: inherit;
    border: 0 solid #1890ff;
    opacity: 0.4;
    -webkit-animation: buttonEffect .4s;
    animation: buttonEffect .4s;
    display: block; }
  .rtl .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
  .rtl .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-btn-two-chinese-chars > * {
    letter-spacing: .34em;
    margin-left: -0.34em; }
@-webkit-keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px; } }
@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px; } }
  .rtl .ant-back-top {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 10;
    position: fixed;
    left: 100px;
    bottom: 50px;
    height: 40px;
    width: 40px;
    cursor: pointer; }
  @media screen and (max-width: 768px) {
    .rtl .ant-back-top {
      left: 60px; } }
  @media screen and (max-width: 480px) {
    .rtl .ant-back-top {
      left: 20px; } }
  .rtl .ant-badge-status-processing:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #1890ff;
    content: '';
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
    animation: antStatusProcessing 1.2s infinite ease-in-out; }
  .rtl .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin-right: 8px; }
  .rtl .ant-breadcrumb-link > .anticon + span {
    margin-right: 4px; }
  .rtl .ant-fullcalendar-month-select {
    margin-right: 5px; }
  .rtl .ant-fullcalendar-header {
    padding: 11px 0 11px 16px;
    text-align: left; }
  .rtl .ant-fullcalendar-header .ant-select-dropdown {
    text-align: right; }
  .rtl .ant-fullcalendar-header .ant-radio-group {
    margin-right: 8px;
    text-align: right; }
  .rtl .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .rtl .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .rtl .ant-fullcalendar-content {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: -9px; }
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
    margin-right: 16px; }
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    text-align: right;
    margin: 0 4px;
    display: block;
    color: rgba(0, 0, 0, 0.65);
    height: 116px;
    padding: 4px 8px;
    border-top: 2px solid #e8e8e8;
    -webkit-transition: background .3s;
    transition: background .3s; }
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
    text-align: left;
    padding-left: 12px;
    padding-bottom: 5px; }
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    text-align: left;
    background: transparent;
    width: auto; }
  .rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 90px;
    overflow-y: auto;
    position: static;
    width: auto;
    right: auto;
    bottom: auto; }
  .rtl span.ant-radio + * {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-radio-button-wrapper {
    margin: 0;
    height: 32px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-right: 0;
    border-top-width: 1.02px;
    background: #fff;
    padding: 0 15px;
    position: relative; }
  .rtl .ant-radio-button-wrapper > .ant-radio-button {
    margin-right: 0;
    display: block;
    width: 0;
    height: 0; }
  .rtl .ant-radio-button-wrapper:not(:first-child)::before {
    content: "";
    display: block;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9d9; }
  .rtl .ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #d9d9d9; }
  .rtl .ant-card-extra {
    float: left;
    padding: 17.5px 0;
    text-align: left;
    margin-right: auto; }
  .rtl .ant-card-grid {
    border-radius: 0;
    border: 0;
    box-shadow: -1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, -1px 1px 0 0 #e8e8e8, -1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
    width: 33.33%;
    float: right;
    padding: 24px;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .rtl .ant-card-actions > li {
    float: right;
    text-align: center;
    margin: 12px 0;
    color: rgba(0, 0, 0, 0.45); }
  .rtl .ant-card-actions > li:not(:last-child) {
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-card-padding-transition .ant-card-extra {
    -webkit-transition: left .3s;
    transition: left .3s; }
  .rtl .ant-card-meta-avatar {
    padding-left: 16px;
    float: right; }
  .rtl .ant-card-loading-block {
    display: inline-block;
    margin: 5px 0 0 2%;
    height: 14px;
    border-radius: 2px;
    background: -webkit-gradient(linear, right top, left top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
    background: linear-gradient(-90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background-size: 600% 600%; }
  .rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    margin: 0;
    border: 1px solid #e8e8e8;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    background: #fafafa;
    margin-left: 2px;
    padding: 0 16px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 38px; }
  .rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 12px;
    margin-right: 3px;
    margin-left: -5px;
    overflow: hidden;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    height: 14px; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
    margin-left: 0; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    border-left: 0;
    border-radius: 0 4px 4px 0;
    margin-left: 1px; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab-active {
    margin-left: -1px;
    padding-left: 18px; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
    margin-right: 0; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab {
    border-right: 0;
    border-radius: 4px 0 0 4px;
    margin-right: 1px; }
  .rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab-active {
    margin-right: -1px;
    padding-right: 18px; }
  .rtl .ant-tabs-ink-bar {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 1px;
    box-sizing: border-box;
    height: 2px;
    background-color: #1890ff;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0; }
  .rtl .ant-tabs-nav-container-scrolling {
    padding-right: 32px;
    padding-left: 32px; }
  .rtl .ant-tabs-tab-prev-icon,
  .rtl .ant-tabs-tab-next-icon {
    font-style: normal;
    font-weight: bold;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    line-height: inherit;
    vertical-align: baseline;
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    text-align: center;
    text-transform: none; }
  .rtl .ant-tabs-tab-next {
    left: 2px; }
  .rtl .ant-tabs-tab-prev {
    right: 0; }
  .rtl .ant-tabs-nav {
    box-sizing: border-box;
    padding-right: 0;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    margin: 0;
    list-style: none;
    display: inline-block; }
  .rtl .ant-tabs-nav .ant-tabs-tab:last-child {
    margin-left: 0; }
  .rtl .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-left: 8px; }
  .rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
    display: -ms-flexbox;
    display: block;
    flex-direction: row;
    will-change: margin-left;
    -webkit-transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .rtl .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-ink-bar {
    width: 2px;
    right: auto;
    height: auto;
    top: 0; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
    float: right;
    border-left: 1px solid #e8e8e8;
    margin-left: -1px;
    margin-bottom: 0; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
    text-align: left; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
    margin-left: -1px; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
    margin-left: -1px; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-ink-bar {
    left: 1px; }
  .rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
    padding-right: 24px;
    border-right: 1px solid #e8e8e8; }
  .rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar {
    float: left;
    border-right: 1px solid #e8e8e8;
    margin-right: -1px;
    margin-bottom: 0; }
  .rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-container {
    margin-right: -1px; }
  .rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
    margin-right: -1px; }
  .rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-ink-bar {
    right: 1px; }
  .rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-content {
    padding-left: 24px;
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-tabs-left .ant-tabs-ink-bar-animated,
  .rtl .ant-tabs-right .ant-tabs-ink-bar-animated {
    -webkit-transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .rtl .no-flex > .ant-tabs-content-animated,
  .rtl .ant-tabs-no-animation > .ant-tabs-content-animated,
  .rtl .ant-tabs-vertical > .ant-tabs-content-animated {
    -webkit-transform: none !important;
    transform: none !important;
    margin-right: 0 !important; }
  .rtl .ant-carousel .slick-track {
    position: relative;
    right: 0;
    top: 0;
    display: block; }
  .rtl .ant-carousel .slick-slide {
    float: right;
    height: 100%;
    min-height: 1px;
    display: none; }
  .rtl [dir="rtl"] .ant-carousel .slick-slide {
    float: left; }
  .rtl .ant-carousel .slick-prev {
    right: -25px; }
  .rtl .ant-carousel .slick-prev:before {
    content: "\2190"; }
  .rtl .ant-carousel .slick-next {
    left: -25px; }
  .rtl .ant-carousel-vertical .slick-dots {
    width: 3px;
    bottom: auto;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: auto; }
  .rtl .ant-table-thead > tr > th .ant-table-column-sorter {
    display: inline; }
  .rtl .ant-cascader-picker-label {
    position: absolute;
    right: 0;
    height: 20px;
    line-height: 20px;
    top: 50%;
    margin-top: -10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding: 0 12px;
    text-align: right;
    line-height: 16px; }
  .rtl .ant-cascader-picker-clear {
    opacity: 0;
    position: absolute;
    left: 12px;
    z-index: 2;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease; }
  .rtl .ant-cascader-picker-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 12px;
    right: auto;
    width: 12px;
    height: 12px;
    font-size: 12px;
    margin-top: -6px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.25); }
  .rtl .ant-cascader-picker-small .ant-cascader-picker-clear,
  .rtl .ant-cascader-picker-small .ant-cascader-picker-arrow {
    left: 8px; }
  .rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomleft,
  .rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomleft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topleft,
  .rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topleft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomleft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topleft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .rtl .ant-cascader-menu {
    display: inline-block;
    vertical-align: top;
    min-width: 111px;
    height: 180px;
    list-style: none;
    margin: 0;
    padding: 0;
    border-left: 1px solid #e8e8e8;
    overflow: auto; }
  .rtl .ant-cascader-menu:last-child {
    border-left-color: transparent;
    margin-left: -1px;
    border-radius: 4px 0 0 4px; }
  .rtl .ant-cascader-menu-item-expand {
    position: relative;
    padding-left: 24px; }
  .rtl .ant-cascader-menu-item-expand:after {
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\E61F";
    display: inline-block;
    font-size: 12px;
    font-size: 8px \9;
    -webkit-transform: scale(0.66666667) rotate(0deg);
    transform: scale(0.66666667) rotate(0deg);
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    left: auto;
    right: 12px;
    display: none; }
  .rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .rtl .ant-cascader-menu-item-loading-icon {
    -webkit-transform: scale(0.83333333) rotate(180deg);
            transform: scale(0.83333333) rotate(180deg);
    position: absolute;
    right: auto;
    left: 12px; }
  .rtl .ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #1890ff;
    content: '';
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .rtl .ant-checkbox-inner {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .rtl .ant-checkbox-input {
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .rtl .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-right: 8px; }
  .rtl .ant-checkbox-wrapper + span,
  .rtl .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-checkbox-group-item {
    display: inline-block;
    margin-left: 8px; }
  .rtl .ant-checkbox-group-item:last-child {
    margin-left: 0; }
  .rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-right: 0; }
  .rtl .ant-row {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    height: auto;
    zoom: 1;
    display: block;
    box-sizing: border-box; }
  .rtl .ant-col-1,
  .rtl .ant-col-xs-1,
  .rtl .ant-col-sm-1,
  .rtl .ant-col-md-1,
  .rtl .ant-col-lg-1,
  .rtl .ant-col-2,
  .rtl .ant-col-xs-2,
  .rtl .ant-col-sm-2,
  .rtl .ant-col-md-2,
  .rtl .ant-col-lg-2,
  .rtl .ant-col-3,
  .rtl .ant-col-xs-3,
  .rtl .ant-col-sm-3,
  .rtl .ant-col-md-3,
  .rtl .ant-col-lg-3,
  .rtl .ant-col-4,
  .rtl .ant-col-xs-4,
  .rtl .ant-col-sm-4,
  .rtl .ant-col-md-4,
  .rtl .ant-col-lg-4,
  .rtl .ant-col-5,
  .rtl .ant-col-xs-5,
  .rtl .ant-col-sm-5,
  .rtl .ant-col-md-5,
  .rtl .ant-col-lg-5,
  .rtl .ant-col-6,
  .rtl .ant-col-xs-6,
  .rtl .ant-col-sm-6,
  .rtl .ant-col-md-6,
  .rtl .ant-col-lg-6,
  .rtl .ant-col-7,
  .rtl .ant-col-xs-7,
  .rtl .ant-col-sm-7,
  .rtl .ant-col-md-7,
  .rtl .ant-col-lg-7,
  .rtl .ant-col-8,
  .rtl .ant-col-xs-8,
  .rtl .ant-col-sm-8,
  .rtl .ant-col-md-8,
  .rtl .ant-col-lg-8,
  .rtl .ant-col-9,
  .rtl .ant-col-xs-9,
  .rtl .ant-col-sm-9,
  .rtl .ant-col-md-9,
  .rtl .ant-col-lg-9,
  .rtl .ant-col-10,
  .rtl .ant-col-xs-10,
  .rtl .ant-col-sm-10,
  .rtl .ant-col-md-10,
  .rtl .ant-col-lg-10,
  .rtl .ant-col-11,
  .rtl .ant-col-xs-11,
  .rtl .ant-col-sm-11,
  .rtl .ant-col-md-11,
  .rtl .ant-col-lg-11,
  .rtl .ant-col-12,
  .rtl .ant-col-xs-12,
  .rtl .ant-col-sm-12,
  .rtl .ant-col-md-12,
  .rtl .ant-col-lg-12,
  .rtl .ant-col-13,
  .rtl .ant-col-xs-13,
  .rtl .ant-col-sm-13,
  .rtl .ant-col-md-13,
  .rtl .ant-col-lg-13,
  .rtl .ant-col-14,
  .rtl .ant-col-xs-14,
  .rtl .ant-col-sm-14,
  .rtl .ant-col-md-14,
  .rtl .ant-col-lg-14,
  .rtl .ant-col-15,
  .rtl .ant-col-xs-15,
  .rtl .ant-col-sm-15,
  .rtl .ant-col-md-15,
  .rtl .ant-col-lg-15,
  .rtl .ant-col-16,
  .rtl .ant-col-xs-16,
  .rtl .ant-col-sm-16,
  .rtl .ant-col-md-16,
  .rtl .ant-col-lg-16,
  .rtl .ant-col-17,
  .rtl .ant-col-xs-17,
  .rtl .ant-col-sm-17,
  .rtl .ant-col-md-17,
  .rtl .ant-col-lg-17,
  .rtl .ant-col-18,
  .rtl .ant-col-xs-18,
  .rtl .ant-col-sm-18,
  .rtl .ant-col-md-18,
  .rtl .ant-col-lg-18,
  .rtl .ant-col-19,
  .rtl .ant-col-xs-19,
  .rtl .ant-col-sm-19,
  .rtl .ant-col-md-19,
  .rtl .ant-col-lg-19,
  .rtl .ant-col-20,
  .rtl .ant-col-xs-20,
  .rtl .ant-col-sm-20,
  .rtl .ant-col-md-20,
  .rtl .ant-col-lg-20,
  .rtl .ant-col-21,
  .rtl .ant-col-xs-21,
  .rtl .ant-col-sm-21,
  .rtl .ant-col-md-21,
  .rtl .ant-col-lg-21,
  .rtl .ant-col-22,
  .rtl .ant-col-xs-22,
  .rtl .ant-col-sm-22,
  .rtl .ant-col-md-22,
  .rtl .ant-col-lg-22,
  .rtl .ant-col-23,
  .rtl .ant-col-xs-23,
  .rtl .ant-col-sm-23,
  .rtl .ant-col-md-23,
  .rtl .ant-col-lg-23,
  .rtl .ant-col-24,
  .rtl .ant-col-xs-24,
  .rtl .ant-col-sm-24,
  .rtl .ant-col-md-24,
  .rtl .ant-col-lg-24 {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0; }
  .rtl .ant-col-1,
  .rtl .ant-col-2,
  .rtl .ant-col-3,
  .rtl .ant-col-4,
  .rtl .ant-col-5,
  .rtl .ant-col-6,
  .rtl .ant-col-7,
  .rtl .ant-col-8,
  .rtl .ant-col-9,
  .rtl .ant-col-10,
  .rtl .ant-col-11,
  .rtl .ant-col-12,
  .rtl .ant-col-13,
  .rtl .ant-col-14,
  .rtl .ant-col-15,
  .rtl .ant-col-16,
  .rtl .ant-col-17,
  .rtl .ant-col-18,
  .rtl .ant-col-19,
  .rtl .ant-col-20,
  .rtl .ant-col-21,
  .rtl .ant-col-22,
  .rtl .ant-col-23,
  .rtl .ant-col-24 {
    float: right;
    flex: 0 0 auto; }
  .rtl .ant-col-push-24 {
    right: 100%; }
  .rtl .ant-col-pull-24 {
    left: 100%; }
  .rtl .ant-col-offset-24 {
    margin-right: 100%; }
  .rtl .ant-col-push-23 {
    right: 95.83333333%; }
  .rtl .ant-col-pull-23 {
    left: 95.83333333%; }
  .rtl .ant-col-offset-23 {
    margin-right: 95.83333333%; }
  .rtl .ant-col-push-22 {
    right: 91.66666667%; }
  .rtl .ant-col-pull-22 {
    left: 91.66666667%; }
  .rtl .ant-col-offset-22 {
    margin-right: 91.66666667%; }
  .rtl .ant-col-push-21 {
    right: 87.5%; }
  .rtl .ant-col-pull-21 {
    left: 87.5%; }
  .rtl .ant-col-offset-21 {
    margin-right: 87.5%; }
  .rtl .ant-col-push-20 {
    right: 83.33333333%; }
  .rtl .ant-col-pull-20 {
    left: 83.33333333%; }
  .rtl .ant-col-offset-20 {
    margin-right: 83.33333333%; }
  .rtl .ant-col-push-19 {
    right: 79.16666667%; }
  .rtl .ant-col-pull-19 {
    left: 79.16666667%; }
  .rtl .ant-col-offset-19 {
    margin-right: 79.16666667%; }
  .rtl .ant-col-push-18 {
    right: 75%; }
  .rtl .ant-col-pull-18 {
    left: 75%; }
  .rtl .ant-col-offset-18 {
    margin-right: 75%; }
  .rtl .ant-col-push-17 {
    right: 70.83333333%; }
  .rtl .ant-col-pull-17 {
    left: 70.83333333%; }
  .rtl .ant-col-offset-17 {
    margin-right: 70.83333333%; }
  .rtl .ant-col-push-16 {
    right: 66.66666667%; }
  .rtl .ant-col-pull-16 {
    left: 66.66666667%; }
  .rtl .ant-col-offset-16 {
    margin-right: 66.66666667%; }
  .rtl .ant-col-push-15 {
    right: 62.5%; }
  .rtl .ant-col-pull-15 {
    left: 62.5%; }
  .rtl .ant-col-offset-15 {
    margin-right: 62.5%; }
  .rtl .ant-col-push-14 {
    right: 58.33333333%; }
  .rtl .ant-col-pull-14 {
    left: 58.33333333%; }
  .rtl .ant-col-offset-14 {
    margin-right: 58.33333333%; }
  .rtl .ant-col-push-13 {
    right: 54.16666667%; }
  .rtl .ant-col-pull-13 {
    left: 54.16666667%; }
  .rtl .ant-col-offset-13 {
    margin-right: 54.16666667%; }
  .rtl .ant-col-push-12 {
    right: 50%; }
  .rtl .ant-col-pull-12 {
    left: 50%; }
  .rtl .ant-col-offset-12 {
    margin-right: 50%; }
  .rtl .ant-col-push-11 {
    right: 45.83333333%; }
  .rtl .ant-col-pull-11 {
    left: 45.83333333%; }
  .rtl .ant-col-offset-11 {
    margin-right: 45.83333333%; }
  .rtl .ant-col-push-10 {
    right: 41.66666667%; }
  .rtl .ant-col-pull-10 {
    left: 41.66666667%; }
  .rtl .ant-col-offset-10 {
    margin-right: 41.66666667%; }
  .rtl .ant-col-push-9 {
    right: 37.5%; }
  .rtl .ant-col-pull-9 {
    left: 37.5%; }
  .rtl .ant-col-offset-9 {
    margin-right: 37.5%; }
  .rtl .ant-col-push-8 {
    right: 33.33333333%; }
  .rtl .ant-col-pull-8 {
    left: 33.33333333%; }
  .rtl .ant-col-offset-8 {
    margin-right: 33.33333333%; }
  .rtl .ant-col-push-7 {
    right: 29.16666667%; }
  .rtl .ant-col-pull-7 {
    left: 29.16666667%; }
  .rtl .ant-col-offset-7 {
    margin-right: 29.16666667%; }
  .rtl .ant-col-push-6 {
    right: 25%; }
  .rtl .ant-col-pull-6 {
    left: 25%; }
  .rtl .ant-col-offset-6 {
    margin-right: 25%; }
  .rtl .ant-col-push-5 {
    right: 20.83333333%; }
  .rtl .ant-col-pull-5 {
    left: 20.83333333%; }
  .rtl .ant-col-offset-5 {
    margin-right: 20.83333333%; }
  .rtl .ant-col-push-4 {
    right: 16.66666667%; }
  .rtl .ant-col-pull-4 {
    left: 16.66666667%; }
  .rtl .ant-col-offset-4 {
    margin-right: 16.66666667%; }
  .rtl .ant-col-push-3 {
    right: 12.5%; }
  .rtl .ant-col-pull-3 {
    left: 12.5%; }
  .rtl .ant-col-offset-3 {
    margin-right: 12.5%; }
  .rtl .ant-col-push-2 {
    right: 8.33333333%; }
  .rtl .ant-col-pull-2 {
    left: 8.33333333%; }
  .rtl .ant-col-offset-2 {
    margin-right: 8.33333333%; }
  .rtl .ant-col-push-1 {
    right: 4.16666667%; }
  .rtl .ant-col-pull-1 {
    left: 4.16666667%; }
  .rtl .ant-col-offset-1 {
    margin-right: 4.16666667%; }
  .rtl .ant-col-push-0 {
    right: auto; }
  .rtl .ant-col-pull-0 {
    left: auto; }
  .rtl .ant-col-push-0 {
    right: auto; }
  .rtl .ant-col-pull-0 {
    left: auto; }
  .rtl .ant-col-offset-0 {
    margin-right: 0; }
  .rtl .ant-col-xs-1,
  .rtl .ant-col-xs-2,
  .rtl .ant-col-xs-3,
  .rtl .ant-col-xs-4,
  .rtl .ant-col-xs-5,
  .rtl .ant-col-xs-6,
  .rtl .ant-col-xs-7,
  .rtl .ant-col-xs-8,
  .rtl .ant-col-xs-9,
  .rtl .ant-col-xs-10,
  .rtl .ant-col-xs-11,
  .rtl .ant-col-xs-12,
  .rtl .ant-col-xs-13,
  .rtl .ant-col-xs-14,
  .rtl .ant-col-xs-15,
  .rtl .ant-col-xs-16,
  .rtl .ant-col-xs-17,
  .rtl .ant-col-xs-18,
  .rtl .ant-col-xs-19,
  .rtl .ant-col-xs-20,
  .rtl .ant-col-xs-21,
  .rtl .ant-col-xs-22,
  .rtl .ant-col-xs-23,
  .rtl .ant-col-xs-24 {
    float: right;
    flex: 0 0 auto; }
  .rtl .ant-col-xs-push-24 {
    right: 100%; }
  .rtl .ant-col-xs-pull-24 {
    left: 100%; }
  .rtl .ant-col-xs-offset-24 {
    margin-right: 100%; }
  .rtl .ant-col-xs-push-23 {
    right: 95.83333333%; }
  .rtl .ant-col-xs-pull-23 {
    left: 95.83333333%; }
  .rtl .ant-col-xs-offset-23 {
    margin-right: 95.83333333%; }
  .rtl .ant-col-xs-push-22 {
    right: 91.66666667%; }
  .rtl .ant-col-xs-pull-22 {
    left: 91.66666667%; }
  .rtl .ant-col-xs-offset-22 {
    margin-right: 91.66666667%; }
  .rtl .ant-col-xs-push-21 {
    right: 87.5%; }
  .rtl .ant-col-xs-pull-21 {
    left: 87.5%; }
  .rtl .ant-col-xs-offset-21 {
    margin-right: 87.5%; }
  .rtl .ant-col-xs-push-20 {
    right: 83.33333333%; }
  .rtl .ant-col-xs-pull-20 {
    left: 83.33333333%; }
  .rtl .ant-col-xs-offset-20 {
    margin-right: 83.33333333%; }
  .rtl .ant-col-xs-push-19 {
    right: 79.16666667%; }
  .rtl .ant-col-xs-pull-19 {
    left: 79.16666667%; }
  .rtl .ant-col-xs-offset-19 {
    margin-right: 79.16666667%; }
  .rtl .ant-col-xs-push-18 {
    right: 75%; }
  .rtl .ant-col-xs-pull-18 {
    left: 75%; }
  .rtl .ant-col-xs-offset-18 {
    margin-right: 75%; }
  .rtl .ant-col-xs-push-17 {
    right: 70.83333333%; }
  .rtl .ant-col-xs-pull-17 {
    left: 70.83333333%; }
  .rtl .ant-col-xs-offset-17 {
    margin-right: 70.83333333%; }
  .rtl .ant-col-xs-push-16 {
    right: 66.66666667%; }
  .rtl .ant-col-xs-pull-16 {
    left: 66.66666667%; }
  .rtl .ant-col-xs-offset-16 {
    margin-right: 66.66666667%; }
  .rtl .ant-col-xs-push-15 {
    right: 62.5%; }
  .rtl .ant-col-xs-pull-15 {
    left: 62.5%; }
  .rtl .ant-col-xs-offset-15 {
    margin-right: 62.5%; }
  .rtl .ant-col-xs-push-14 {
    right: 58.33333333%; }
  .rtl .ant-col-xs-pull-14 {
    left: 58.33333333%; }
  .rtl .ant-col-xs-offset-14 {
    margin-right: 58.33333333%; }
  .rtl .ant-col-xs-push-13 {
    right: 54.16666667%; }
  .rtl .ant-col-xs-pull-13 {
    left: 54.16666667%; }
  .rtl .ant-col-xs-offset-13 {
    margin-right: 54.16666667%; }
  .rtl .ant-col-xs-push-12 {
    right: 50%; }
  .rtl .ant-col-xs-pull-12 {
    left: 50%; }
  .rtl .ant-col-xs-offset-12 {
    margin-right: 50%; }
  .rtl .ant-col-xs-push-11 {
    right: 45.83333333%; }
  .rtl .ant-col-xs-pull-11 {
    left: 45.83333333%; }
  .rtl .ant-col-xs-offset-11 {
    margin-right: 45.83333333%; }
  .rtl .ant-col-xs-push-10 {
    right: 41.66666667%; }
  .rtl .ant-col-xs-pull-10 {
    left: 41.66666667%; }
  .rtl .ant-col-xs-offset-10 {
    margin-right: 41.66666667%; }
  .rtl .ant-col-xs-push-9 {
    right: 37.5%; }
  .rtl .ant-col-xs-pull-9 {
    left: 37.5%; }
  .rtl .ant-col-xs-offset-9 {
    margin-right: 37.5%; }
  .rtl .ant-col-xs-push-8 {
    right: 33.33333333%; }
  .rtl .ant-col-xs-pull-8 {
    left: 33.33333333%; }
  .rtl .ant-col-xs-offset-8 {
    margin-right: 33.33333333%; }
  .rtl .ant-col-xs-push-7 {
    right: 29.16666667%; }
  .rtl .ant-col-xs-pull-7 {
    left: 29.16666667%; }
  .rtl .ant-col-xs-offset-7 {
    margin-right: 29.16666667%; }
  .rtl .ant-col-xs-push-6 {
    right: 25%; }
  .rtl .ant-col-xs-pull-6 {
    left: 25%; }
  .rtl .ant-col-xs-offset-6 {
    margin-right: 25%; }
  .rtl .ant-col-xs-push-5 {
    right: 20.83333333%; }
  .rtl .ant-col-xs-pull-5 {
    left: 20.83333333%; }
  .rtl .ant-col-xs-offset-5 {
    margin-right: 20.83333333%; }
  .rtl .ant-col-xs-push-4 {
    right: 16.66666667%; }
  .rtl .ant-col-xs-pull-4 {
    left: 16.66666667%; }
  .rtl .ant-col-xs-offset-4 {
    margin-right: 16.66666667%; }
  .rtl .ant-col-xs-push-3 {
    right: 12.5%; }
  .rtl .ant-col-xs-pull-3 {
    left: 12.5%; }
  .rtl .ant-col-xs-offset-3 {
    margin-right: 12.5%; }
  .rtl .ant-col-xs-push-2 {
    right: 8.33333333%; }
  .rtl .ant-col-xs-pull-2 {
    left: 8.33333333%; }
  .rtl .ant-col-xs-offset-2 {
    margin-right: 8.33333333%; }
  .rtl .ant-col-xs-push-1 {
    right: 4.16666667%; }
  .rtl .ant-col-xs-pull-1 {
    left: 4.16666667%; }
  .rtl .ant-col-xs-offset-1 {
    margin-right: 4.16666667%; }
  .rtl .ant-col-push-0 {
    right: auto; }
  .rtl .ant-col-pull-0 {
    left: auto; }
  .rtl .ant-col-xs-push-0 {
    right: auto; }
  .rtl .ant-col-xs-pull-0 {
    left: auto; }
  .rtl .ant-col-xs-offset-0 {
    margin-right: 0; }
  @media (min-width: 576px) {
    .rtl .ant-col-sm-1,
    .rtl .ant-col-sm-2,
    .rtl .ant-col-sm-3,
    .rtl .ant-col-sm-4,
    .rtl .ant-col-sm-5,
    .rtl .ant-col-sm-6,
    .rtl .ant-col-sm-7,
    .rtl .ant-col-sm-8,
    .rtl .ant-col-sm-9,
    .rtl .ant-col-sm-10,
    .rtl .ant-col-sm-11,
    .rtl .ant-col-sm-12,
    .rtl .ant-col-sm-13,
    .rtl .ant-col-sm-14,
    .rtl .ant-col-sm-15,
    .rtl .ant-col-sm-16,
    .rtl .ant-col-sm-17,
    .rtl .ant-col-sm-18,
    .rtl .ant-col-sm-19,
    .rtl .ant-col-sm-20,
    .rtl .ant-col-sm-21,
    .rtl .ant-col-sm-22,
    .rtl .ant-col-sm-23,
    .rtl .ant-col-sm-24 {
      float: right;
      flex: 0 0 auto; }
    .rtl .ant-col-sm-push-24 {
      right: 100%; }
    .rtl .ant-col-sm-pull-24 {
      left: 100%; }
    .rtl .ant-col-sm-offset-24 {
      margin-right: 100%; }
    .rtl .ant-col-sm-push-23 {
      right: 95.83333333%; }
    .rtl .ant-col-sm-pull-23 {
      left: 95.83333333%; }
    .rtl .ant-col-sm-offset-23 {
      margin-right: 95.83333333%; }
    .rtl .ant-col-sm-push-22 {
      right: 91.66666667%; }
    .rtl .ant-col-sm-pull-22 {
      left: 91.66666667%; }
    .rtl .ant-col-sm-offset-22 {
      margin-right: 91.66666667%; }
    .rtl .ant-col-sm-push-21 {
      right: 87.5%; }
    .rtl .ant-col-sm-pull-21 {
      left: 87.5%; }
    .rtl .ant-col-sm-offset-21 {
      margin-right: 87.5%; }
    .rtl .ant-col-sm-push-20 {
      right: 83.33333333%; }
    .rtl .ant-col-sm-pull-20 {
      left: 83.33333333%; }
    .rtl .ant-col-sm-offset-20 {
      margin-right: 83.33333333%; }
    .rtl .ant-col-sm-push-19 {
      right: 79.16666667%; }
    .rtl .ant-col-sm-pull-19 {
      left: 79.16666667%; }
    .rtl .ant-col-sm-offset-19 {
      margin-right: 79.16666667%; }
    .rtl .ant-col-sm-push-18 {
      right: 75%; }
    .rtl .ant-col-sm-pull-18 {
      left: 75%; }
    .rtl .ant-col-sm-offset-18 {
      margin-right: 75%; }
    .rtl .ant-col-sm-push-17 {
      right: 70.83333333%; }
    .rtl .ant-col-sm-pull-17 {
      left: 70.83333333%; }
    .rtl .ant-col-sm-offset-17 {
      margin-right: 70.83333333%; }
    .rtl .ant-col-sm-push-16 {
      right: 66.66666667%; }
    .rtl .ant-col-sm-pull-16 {
      left: 66.66666667%; }
    .rtl .ant-col-sm-offset-16 {
      margin-right: 66.66666667%; }
    .rtl .ant-col-sm-push-15 {
      right: 62.5%; }
    .rtl .ant-col-sm-pull-15 {
      left: 62.5%; }
    .rtl .ant-col-sm-offset-15 {
      margin-right: 62.5%; }
    .rtl .ant-col-sm-push-14 {
      right: 58.33333333%; }
    .rtl .ant-col-sm-pull-14 {
      left: 58.33333333%; }
    .rtl .ant-col-sm-offset-14 {
      margin-right: 58.33333333%; }
    .rtl .ant-col-sm-push-13 {
      right: 54.16666667%; }
    .rtl .ant-col-sm-pull-13 {
      left: 54.16666667%; }
    .rtl .ant-col-sm-offset-13 {
      margin-right: 54.16666667%; }
    .rtl .ant-col-sm-push-12 {
      right: 50%; }
    .rtl .ant-col-sm-pull-12 {
      left: 50%; }
    .rtl .ant-col-sm-offset-12 {
      margin-right: 50%; }
    .rtl .ant-col-sm-push-11 {
      right: 45.83333333%; }
    .rtl .ant-col-sm-pull-11 {
      left: 45.83333333%; }
    .rtl .ant-col-sm-offset-11 {
      margin-right: 45.83333333%; }
    .rtl .ant-col-sm-push-10 {
      right: 41.66666667%; }
    .rtl .ant-col-sm-pull-10 {
      left: 41.66666667%; }
    .rtl .ant-col-sm-offset-10 {
      margin-right: 41.66666667%; }
    .rtl .ant-col-sm-push-9 {
      right: 37.5%; }
    .rtl .ant-col-sm-pull-9 {
      left: 37.5%; }
    .rtl .ant-col-sm-offset-9 {
      margin-right: 37.5%; }
    .rtl .ant-col-sm-push-8 {
      right: 33.33333333%; }
    .rtl .ant-col-sm-pull-8 {
      left: 33.33333333%; }
    .rtl .ant-col-sm-offset-8 {
      margin-right: 33.33333333%; }
    .rtl .ant-col-sm-push-7 {
      right: 29.16666667%; }
    .rtl .ant-col-sm-pull-7 {
      left: 29.16666667%; }
    .rtl .ant-col-sm-offset-7 {
      margin-right: 29.16666667%; }
    .rtl .ant-col-sm-push-6 {
      right: 25%; }
    .rtl .ant-col-sm-pull-6 {
      left: 25%; }
    .rtl .ant-col-sm-offset-6 {
      margin-right: 25%; }
    .rtl .ant-col-sm-push-5 {
      right: 20.83333333%; }
    .rtl .ant-col-sm-pull-5 {
      left: 20.83333333%; }
    .rtl .ant-col-sm-offset-5 {
      margin-right: 20.83333333%; }
    .rtl .ant-col-sm-push-4 {
      right: 16.66666667%; }
    .rtl .ant-col-sm-pull-4 {
      left: 16.66666667%; }
    .rtl .ant-col-sm-offset-4 {
      margin-right: 16.66666667%; }
    .rtl .ant-col-sm-push-3 {
      right: 12.5%; }
    .rtl .ant-col-sm-pull-3 {
      left: 12.5%; }
    .rtl .ant-col-sm-offset-3 {
      margin-right: 12.5%; }
    .rtl .ant-col-sm-push-2 {
      right: 8.33333333%; }
    .rtl .ant-col-sm-pull-2 {
      left: 8.33333333%; }
    .rtl .ant-col-sm-offset-2 {
      margin-right: 8.33333333%; }
    .rtl .ant-col-sm-push-1 {
      right: 4.16666667%; }
    .rtl .ant-col-sm-pull-1 {
      left: 4.16666667%; }
    .rtl .ant-col-sm-offset-1 {
      margin-right: 4.16666667%; }
    .rtl .ant-col-push-0 {
      right: auto; }
    .rtl .ant-col-pull-0 {
      left: auto; }
    .rtl .ant-col-sm-push-0 {
      right: auto; }
    .rtl .ant-col-sm-pull-0 {
      left: auto; }
    .rtl .ant-col-sm-offset-0 {
      margin-right: 0; } }
  @media (min-width: 768px) {
    .rtl .ant-col-md-1,
    .rtl .ant-col-md-2,
    .rtl .ant-col-md-3,
    .rtl .ant-col-md-4,
    .rtl .ant-col-md-5,
    .rtl .ant-col-md-6,
    .rtl .ant-col-md-7,
    .rtl .ant-col-md-8,
    .rtl .ant-col-md-9,
    .rtl .ant-col-md-10,
    .rtl .ant-col-md-11,
    .rtl .ant-col-md-12,
    .rtl .ant-col-md-13,
    .rtl .ant-col-md-14,
    .rtl .ant-col-md-15,
    .rtl .ant-col-md-16,
    .rtl .ant-col-md-17,
    .rtl .ant-col-md-18,
    .rtl .ant-col-md-19,
    .rtl .ant-col-md-20,
    .rtl .ant-col-md-21,
    .rtl .ant-col-md-22,
    .rtl .ant-col-md-23,
    .rtl .ant-col-md-24 {
      float: right;
      flex: 0 0 auto; }
    .rtl .ant-col-md-push-24 {
      right: 100%; }
    .rtl .ant-col-md-pull-24 {
      left: 100%; }
    .rtl .ant-col-md-offset-24 {
      margin-right: 100%; }
    .rtl .ant-col-md-push-23 {
      right: 95.83333333%; }
    .rtl .ant-col-md-pull-23 {
      left: 95.83333333%; }
    .rtl .ant-col-md-offset-23 {
      margin-right: 95.83333333%; }
    .rtl .ant-col-md-push-22 {
      right: 91.66666667%; }
    .rtl .ant-col-md-pull-22 {
      left: 91.66666667%; }
    .rtl .ant-col-md-offset-22 {
      margin-right: 91.66666667%; }
    .rtl .ant-col-md-push-21 {
      right: 87.5%; }
    .rtl .ant-col-md-pull-21 {
      left: 87.5%; }
    .rtl .ant-col-md-offset-21 {
      margin-right: 87.5%; }
    .rtl .ant-col-md-push-20 {
      right: 83.33333333%; }
    .rtl .ant-col-md-pull-20 {
      left: 83.33333333%; }
    .rtl .ant-col-md-offset-20 {
      margin-right: 83.33333333%; }
    .rtl .ant-col-md-push-19 {
      right: 79.16666667%; }
    .rtl .ant-col-md-pull-19 {
      left: 79.16666667%; }
    .rtl .ant-col-md-offset-19 {
      margin-right: 79.16666667%; }
    .rtl .ant-col-md-push-18 {
      right: 75%; }
    .rtl .ant-col-md-pull-18 {
      left: 75%; }
    .rtl .ant-col-md-offset-18 {
      margin-right: 75%; }
    .rtl .ant-col-md-push-17 {
      right: 70.83333333%; }
    .rtl .ant-col-md-pull-17 {
      left: 70.83333333%; }
    .rtl .ant-col-md-offset-17 {
      margin-right: 70.83333333%; }
    .rtl .ant-col-md-push-16 {
      right: 66.66666667%; }
    .rtl .ant-col-md-pull-16 {
      left: 66.66666667%; }
    .rtl .ant-col-md-offset-16 {
      margin-right: 66.66666667%; }
    .rtl .ant-col-md-push-15 {
      right: 62.5%; }
    .rtl .ant-col-md-pull-15 {
      left: 62.5%; }
    .rtl .ant-col-md-offset-15 {
      margin-right: 62.5%; }
    .rtl .ant-col-md-push-14 {
      right: 58.33333333%; }
    .rtl .ant-col-md-pull-14 {
      left: 58.33333333%; }
    .rtl .ant-col-md-offset-14 {
      margin-right: 58.33333333%; }
    .rtl .ant-col-md-push-13 {
      right: 54.16666667%; }
    .rtl .ant-col-md-pull-13 {
      left: 54.16666667%; }
    .rtl .ant-col-md-offset-13 {
      margin-right: 54.16666667%; }
    .rtl .ant-col-md-push-12 {
      right: 50%; }
    .rtl .ant-col-md-pull-12 {
      left: 50%; }
    .rtl .ant-col-md-offset-12 {
      margin-right: 50%; }
    .rtl .ant-col-md-push-11 {
      right: 45.83333333%; }
    .rtl .ant-col-md-pull-11 {
      left: 45.83333333%; }
    .rtl .ant-col-md-offset-11 {
      margin-right: 45.83333333%; }
    .rtl .ant-col-md-push-10 {
      right: 41.66666667%; }
    .rtl .ant-col-md-pull-10 {
      left: 41.66666667%; }
    .rtl .ant-col-md-offset-10 {
      margin-right: 41.66666667%; }
    .rtl .ant-col-md-push-9 {
      right: 37.5%; }
    .rtl .ant-col-md-pull-9 {
      left: 37.5%; }
    .rtl .ant-col-md-offset-9 {
      margin-right: 37.5%; }
    .rtl .ant-col-md-push-8 {
      right: 33.33333333%; }
    .rtl .ant-col-md-pull-8 {
      left: 33.33333333%; }
    .rtl .ant-col-md-offset-8 {
      margin-right: 33.33333333%; }
    .rtl .ant-col-md-order-8 {
      order: 8; }
    .rtl .ant-col-md-push-7 {
      right: 29.16666667%; }
    .rtl .ant-col-md-pull-7 {
      left: 29.16666667%; }
    .rtl .ant-col-md-offset-7 {
      margin-right: 29.16666667%; }
    .rtl .ant-col-md-push-6 {
      right: 25%; }
    .rtl .ant-col-md-pull-6 {
      left: 25%; }
    .rtl .ant-col-md-offset-6 {
      margin-right: 25%; }
    .rtl .ant-col-md-push-5 {
      right: 20.83333333%; }
    .rtl .ant-col-md-pull-5 {
      left: 20.83333333%; }
    .rtl .ant-col-md-offset-5 {
      margin-right: 20.83333333%; }
    .rtl .ant-col-md-push-4 {
      right: 16.66666667%; }
    .rtl .ant-col-md-pull-4 {
      left: 16.66666667%; }
    .rtl .ant-col-md-offset-4 {
      margin-right: 16.66666667%; }
    .rtl .ant-col-md-push-3 {
      right: 12.5%; }
    .rtl .ant-col-md-pull-3 {
      left: 12.5%; }
    .rtl .ant-col-md-offset-3 {
      margin-right: 12.5%; }
    .rtl .ant-col-md-push-2 {
      right: 8.33333333%; }
    .rtl .ant-col-md-pull-2 {
      left: 8.33333333%; }
    .rtl .ant-col-md-offset-2 {
      margin-right: 8.33333333%; }
    .rtl .ant-col-md-push-1 {
      right: 4.16666667%; }
    .rtl .ant-col-md-pull-1 {
      left: 4.16666667%; }
    .rtl .ant-col-md-offset-1 {
      margin-right: 4.16666667%; }
    .rtl .ant-col-push-0 {
      right: auto; }
    .rtl .ant-col-pull-0 {
      left: auto; }
    .rtl .ant-col-md-push-0 {
      right: auto; }
    .rtl .ant-col-md-pull-0 {
      left: auto; }
    .rtl .ant-col-md-offset-0 {
      margin-right: 0; } }
  @media (min-width: 992px) {
    .rtl .ant-col-lg-1,
    .rtl .ant-col-lg-2,
    .rtl .ant-col-lg-3,
    .rtl .ant-col-lg-4,
    .rtl .ant-col-lg-5,
    .rtl .ant-col-lg-6,
    .rtl .ant-col-lg-7,
    .rtl .ant-col-lg-8,
    .rtl .ant-col-lg-9,
    .rtl .ant-col-lg-10,
    .rtl .ant-col-lg-11,
    .rtl .ant-col-lg-12,
    .rtl .ant-col-lg-13,
    .rtl .ant-col-lg-14,
    .rtl .ant-col-lg-15,
    .rtl .ant-col-lg-16,
    .rtl .ant-col-lg-17,
    .rtl .ant-col-lg-18,
    .rtl .ant-col-lg-19,
    .rtl .ant-col-lg-20,
    .rtl .ant-col-lg-21,
    .rtl .ant-col-lg-22,
    .rtl .ant-col-lg-23,
    .rtl .ant-col-lg-24 {
      float: right;
      flex: 0 0 auto; }
    .rtl .ant-col-lg-push-24 {
      right: 100%; }
    .rtl .ant-col-lg-pull-24 {
      left: 100%; }
    .rtl .ant-col-lg-offset-24 {
      margin-right: 100%; }
    .rtl .ant-col-lg-push-23 {
      right: 95.83333333%; }
    .rtl .ant-col-lg-pull-23 {
      left: 95.83333333%; }
    .rtl .ant-col-lg-offset-23 {
      margin-right: 95.83333333%; }
    .rtl .ant-col-lg-push-22 {
      right: 91.66666667%; }
    .rtl .ant-col-lg-pull-22 {
      left: 91.66666667%; }
    .rtl .ant-col-lg-offset-22 {
      margin-right: 91.66666667%; }
    .rtl .ant-col-lg-push-21 {
      right: 87.5%; }
    .rtl .ant-col-lg-pull-21 {
      left: 87.5%; }
    .rtl .ant-col-lg-offset-21 {
      margin-right: 87.5%; }
    .rtl .ant-col-lg-push-20 {
      right: 83.33333333%; }
    .rtl .ant-col-lg-pull-20 {
      left: 83.33333333%; }
    .rtl .ant-col-lg-offset-20 {
      margin-right: 83.33333333%; }
    .rtl .ant-col-lg-push-19 {
      right: 79.16666667%; }
    .rtl .ant-col-lg-pull-19 {
      left: 79.16666667%; }
    .rtl .ant-col-lg-offset-19 {
      margin-right: 79.16666667%; }
    .rtl .ant-col-lg-push-18 {
      right: 75%; }
    .rtl .ant-col-lg-pull-18 {
      left: 75%; }
    .rtl .ant-col-lg-offset-18 {
      margin-right: 75%; }
    .rtl .ant-col-lg-push-17 {
      right: 70.83333333%; }
    .rtl .ant-col-lg-pull-17 {
      left: 70.83333333%; }
    .rtl .ant-col-lg-offset-17 {
      margin-right: 70.83333333%; }
    .rtl .ant-col-lg-push-16 {
      right: 66.66666667%; }
    .rtl .ant-col-lg-pull-16 {
      left: 66.66666667%; }
    .rtl .ant-col-lg-offset-16 {
      margin-right: 66.66666667%; }
    .rtl .ant-col-lg-push-15 {
      right: 62.5%; }
    .rtl .ant-col-lg-pull-15 {
      left: 62.5%; }
    .rtl .ant-col-lg-offset-15 {
      margin-right: 62.5%; }
    .rtl .ant-col-lg-push-14 {
      right: 58.33333333%; }
    .rtl .ant-col-lg-pull-14 {
      left: 58.33333333%; }
    .rtl .ant-col-lg-offset-14 {
      margin-right: 58.33333333%; }
    .rtl .ant-col-lg-push-13 {
      right: 54.16666667%; }
    .rtl .ant-col-lg-pull-13 {
      left: 54.16666667%; }
    .rtl .ant-col-lg-offset-13 {
      margin-right: 54.16666667%; }
    .rtl .ant-col-lg-push-12 {
      right: 50%; }
    .rtl .ant-col-lg-pull-12 {
      left: 50%; }
    .rtl .ant-col-lg-offset-12 {
      margin-right: 50%; }
    .rtl .ant-col-lg-push-11 {
      right: 45.83333333%; }
    .rtl .ant-col-lg-pull-11 {
      left: 45.83333333%; }
    .rtl .ant-col-lg-offset-11 {
      margin-right: 45.83333333%; }
    .rtl .ant-col-lg-push-10 {
      right: 41.66666667%; }
    .rtl .ant-col-lg-pull-10 {
      left: 41.66666667%; }
    .rtl .ant-col-lg-offset-10 {
      margin-right: 41.66666667%; }
    .rtl .ant-col-lg-push-9 {
      right: 37.5%; }
    .rtl .ant-col-lg-pull-9 {
      left: 37.5%; }
    .rtl .ant-col-lg-offset-9 {
      margin-right: 37.5%; }
    .rtl .ant-col-lg-push-8 {
      right: 33.33333333%; }
    .rtl .ant-col-lg-pull-8 {
      left: 33.33333333%; }
    .rtl .ant-col-lg-offset-8 {
      margin-right: 33.33333333%; }
    .rtl .ant-col-lg-push-7 {
      right: 29.16666667%; }
    .rtl .ant-col-lg-pull-7 {
      left: 29.16666667%; }
    .rtl .ant-col-lg-offset-7 {
      margin-right: 29.16666667%; }
    .rtl .ant-col-lg-push-6 {
      right: 25%; }
    .rtl .ant-col-lg-pull-6 {
      left: 25%; }
    .rtl .ant-col-lg-offset-6 {
      margin-right: 25%; }
    .rtl .ant-col-lg-push-5 {
      right: 20.83333333%; }
    .rtl .ant-col-lg-pull-5 {
      left: 20.83333333%; }
    .rtl .ant-col-lg-offset-5 {
      margin-right: 20.83333333%; }
    .rtl .ant-col-lg-push-4 {
      right: 16.66666667%; }
    .rtl .ant-col-lg-pull-4 {
      left: 16.66666667%; }
    .rtl .ant-col-lg-offset-4 {
      margin-right: 16.66666667%; }
    .rtl .ant-col-lg-push-3 {
      right: 12.5%; }
    .rtl .ant-col-lg-pull-3 {
      left: 12.5%; }
    .rtl .ant-col-lg-offset-3 {
      margin-right: 12.5%; }
    .rtl .ant-col-lg-push-2 {
      right: 8.33333333%; }
    .rtl .ant-col-lg-pull-2 {
      left: 8.33333333%; }
    .rtl .ant-col-lg-offset-2 {
      margin-right: 8.33333333%; }
    .rtl .ant-col-lg-push-1 {
      right: 4.16666667%; }
    .rtl .ant-col-lg-pull-1 {
      left: 4.16666667%; }
    .rtl .ant-col-lg-offset-1 {
      margin-right: 4.16666667%; }
    .rtl .ant-col-push-0 {
      right: auto; }
    .rtl .ant-col-pull-0 {
      left: auto; }
    .rtl .ant-col-lg-push-0 {
      right: auto; }
    .rtl .ant-col-lg-pull-0 {
      left: auto; }
    .rtl .ant-col-lg-offset-0 {
      margin-right: 0; } }
  @media (min-width: 1200px) {
    .rtl .ant-col-xl-1,
    .rtl .ant-col-xl-2,
    .rtl .ant-col-xl-3,
    .rtl .ant-col-xl-4,
    .rtl .ant-col-xl-5,
    .rtl .ant-col-xl-6,
    .rtl .ant-col-xl-7,
    .rtl .ant-col-xl-8,
    .rtl .ant-col-xl-9,
    .rtl .ant-col-xl-10,
    .rtl .ant-col-xl-11,
    .rtl .ant-col-xl-12,
    .rtl .ant-col-xl-13,
    .rtl .ant-col-xl-14,
    .rtl .ant-col-xl-15,
    .rtl .ant-col-xl-16,
    .rtl .ant-col-xl-17,
    .rtl .ant-col-xl-18,
    .rtl .ant-col-xl-19,
    .rtl .ant-col-xl-20,
    .rtl .ant-col-xl-21,
    .rtl .ant-col-xl-22,
    .rtl .ant-col-xl-23,
    .rtl .ant-col-xl-24 {
      float: right;
      flex: 0 0 auto; }
    .rtl .ant-col-xl-push-24 {
      right: 100%; }
    .rtl .ant-col-xl-pull-24 {
      left: 100%; }
    .rtl .ant-col-xl-offset-24 {
      margin-right: 100%; }
    .rtl .ant-col-xl-push-23 {
      right: 95.83333333%; }
    .rtl .ant-col-xl-pull-23 {
      left: 95.83333333%; }
    .rtl .ant-col-xl-offset-23 {
      margin-right: 95.83333333%; }
    .rtl .ant-col-xl-push-22 {
      right: 91.66666667%; }
    .rtl .ant-col-xl-pull-22 {
      left: 91.66666667%; }
    .rtl .ant-col-xl-offset-22 {
      margin-right: 91.66666667%; }
    .rtl .ant-col-xl-push-21 {
      right: 87.5%; }
    .rtl .ant-col-xl-pull-21 {
      left: 87.5%; }
    .rtl .ant-col-xl-offset-21 {
      margin-right: 87.5%; }
    .rtl .ant-col-xl-push-20 {
      right: 83.33333333%; }
    .rtl .ant-col-xl-pull-20 {
      left: 83.33333333%; }
    .rtl .ant-col-xl-offset-20 {
      margin-right: 83.33333333%; }
    .rtl .ant-col-xl-push-19 {
      right: 79.16666667%; }
    .rtl .ant-col-xl-pull-19 {
      left: 79.16666667%; }
    .rtl .ant-col-xl-offset-19 {
      margin-right: 79.16666667%; }
    .rtl .ant-col-xl-push-18 {
      right: 75%; }
    .rtl .ant-col-xl-pull-18 {
      left: 75%; }
    .rtl .ant-col-xl-offset-18 {
      margin-right: 75%; }
    .rtl .ant-col-xl-push-17 {
      right: 70.83333333%; }
    .rtl .ant-col-xl-pull-17 {
      left: 70.83333333%; }
    .rtl .ant-col-xl-offset-17 {
      margin-right: 70.83333333%; }
    .rtl .ant-col-xl-push-16 {
      right: 66.66666667%; }
    .rtl .ant-col-xl-pull-16 {
      left: 66.66666667%; }
    .rtl .ant-col-xl-offset-16 {
      margin-right: 66.66666667%; }
    .rtl .ant-col-xl-push-15 {
      right: 62.5%; }
    .rtl .ant-col-xl-pull-15 {
      left: 62.5%; }
    .rtl .ant-col-xl-offset-15 {
      margin-right: 62.5%; }
    .rtl .ant-col-xl-push-14 {
      right: 58.33333333%; }
    .rtl .ant-col-xl-pull-14 {
      left: 58.33333333%; }
    .rtl .ant-col-xl-offset-14 {
      margin-right: 58.33333333%; }
    .rtl .ant-col-xl-push-13 {
      right: 54.16666667%; }
    .rtl .ant-col-xl-pull-13 {
      left: 54.16666667%; }
    .rtl .ant-col-xl-offset-13 {
      margin-right: 54.16666667%; }
    .rtl .ant-col-xl-push-12 {
      right: 50%; }
    .rtl .ant-col-xl-pull-12 {
      left: 50%; }
    .rtl .ant-col-xl-offset-12 {
      margin-right: 50%; }
    .rtl .ant-col-xl-push-11 {
      right: 45.83333333%; }
    .rtl .ant-col-xl-pull-11 {
      left: 45.83333333%; }
    .rtl .ant-col-xl-offset-11 {
      margin-right: 45.83333333%; }
    .rtl .ant-col-xl-push-10 {
      right: 41.66666667%; }
    .rtl .ant-col-xl-pull-10 {
      left: 41.66666667%; }
    .rtl .ant-col-xl-offset-10 {
      margin-right: 41.66666667%; }
    .rtl .ant-col-xl-push-9 {
      right: 37.5%; }
    .rtl .ant-col-xl-pull-9 {
      left: 37.5%; }
    .rtl .ant-col-xl-offset-9 {
      margin-right: 37.5%; }
    .rtl .ant-col-xl-push-8 {
      right: 33.33333333%; }
    .rtl .ant-col-xl-pull-8 {
      left: 33.33333333%; }
    .rtl .ant-col-xl-offset-8 {
      margin-right: 33.33333333%; }
    .rtl .ant-col-xl-push-7 {
      right: 29.16666667%; }
    .rtl .ant-col-xl-pull-7 {
      left: 29.16666667%; }
    .rtl .ant-col-xl-offset-7 {
      margin-right: 29.16666667%; }
    .rtl .ant-col-xl-push-6 {
      right: 25%; }
    .rtl .ant-col-xl-pull-6 {
      left: 25%; }
    .rtl .ant-col-xl-offset-6 {
      margin-right: 25%; }
    .rtl .ant-col-xl-push-5 {
      right: 20.83333333%; }
    .rtl .ant-col-xl-pull-5 {
      left: 20.83333333%; }
    .rtl .ant-col-xl-offset-5 {
      margin-right: 20.83333333%; }
    .rtl .ant-col-xl-push-4 {
      right: 16.66666667%; }
    .rtl .ant-col-xl-pull-4 {
      left: 16.66666667%; }
    .rtl .ant-col-xl-offset-4 {
      margin-right: 16.66666667%; }
    .rtl .ant-col-xl-push-3 {
      right: 12.5%; }
    .rtl .ant-col-xl-pull-3 {
      left: 12.5%; }
    .rtl .ant-col-xl-offset-3 {
      margin-right: 12.5%; }
    .rtl .ant-col-xl-push-2 {
      right: 8.33333333%; }
    .rtl .ant-col-xl-pull-2 {
      left: 8.33333333%; }
    .rtl .ant-col-xl-offset-2 {
      margin-right: 8.33333333%; }
    .rtl .ant-col-xl-push-1 {
      right: 4.16666667%; }
    .rtl .ant-col-xl-pull-1 {
      left: 4.16666667%; }
    .rtl .ant-col-xl-offset-1 {
      margin-right: 4.16666667%; }
    .rtl .ant-col-push-0 {
      right: auto; }
    .rtl .ant-col-pull-0 {
      left: auto; }
    .rtl .ant-col-xl-push-0 {
      right: auto; }
    .rtl .ant-col-xl-pull-0 {
      left: auto; }
    .rtl .ant-col-xl-offset-0 {
      margin-right: 0; }
    .rtl .ant-col-xl-order-0 {
      order: 0; } }
  @media (min-width: 1600px) {
    .rtl .ant-col-xxl-1,
    .rtl .ant-col-xxl-2,
    .rtl .ant-col-xxl-3,
    .rtl .ant-col-xxl-4,
    .rtl .ant-col-xxl-5,
    .rtl .ant-col-xxl-6,
    .rtl .ant-col-xxl-7,
    .rtl .ant-col-xxl-8,
    .rtl .ant-col-xxl-9,
    .rtl .ant-col-xxl-10,
    .rtl .ant-col-xxl-11,
    .rtl .ant-col-xxl-12,
    .rtl .ant-col-xxl-13,
    .rtl .ant-col-xxl-14,
    .rtl .ant-col-xxl-15,
    .rtl .ant-col-xxl-16,
    .rtl .ant-col-xxl-17,
    .rtl .ant-col-xxl-18,
    .rtl .ant-col-xxl-19,
    .rtl .ant-col-xxl-20,
    .rtl .ant-col-xxl-21,
    .rtl .ant-col-xxl-22,
    .rtl .ant-col-xxl-23,
    .rtl .ant-col-xxl-24 {
      float: right;
      flex: 0 0 auto; }
    .rtl .ant-col-xxl-push-24 {
      right: 100%; }
    .rtl .ant-col-xxl-pull-24 {
      left: 100%; }
    .rtl .ant-col-xxl-offset-24 {
      margin-right: 100%; }
    .rtl .ant-col-xxl-push-23 {
      right: 95.83333333%; }
    .rtl .ant-col-xxl-pull-23 {
      left: 95.83333333%; }
    .rtl .ant-col-xxl-offset-23 {
      margin-right: 95.83333333%; }
    .rtl .ant-col-xxl-push-22 {
      right: 91.66666667%; }
    .rtl .ant-col-xxl-pull-22 {
      left: 91.66666667%; }
    .rtl .ant-col-xxl-offset-22 {
      margin-right: 91.66666667%; }
    .rtl .ant-col-xxl-push-21 {
      right: 87.5%; }
    .rtl .ant-col-xxl-pull-21 {
      left: 87.5%; }
    .rtl .ant-col-xxl-offset-21 {
      margin-right: 87.5%; }
    .rtl .ant-col-xxl-push-20 {
      right: 83.33333333%; }
    .rtl .ant-col-xxl-pull-20 {
      left: 83.33333333%; }
    .rtl .ant-col-xxl-offset-20 {
      margin-right: 83.33333333%; }
    .rtl .ant-col-xxl-push-19 {
      right: 79.16666667%; }
    .rtl .ant-col-xxl-pull-19 {
      left: 79.16666667%; }
    .rtl .ant-col-xxl-offset-19 {
      margin-right: 79.16666667%; }
    .rtl .ant-col-xxl-push-18 {
      right: 75%; }
    .rtl .ant-col-xxl-pull-18 {
      left: 75%; }
    .rtl .ant-col-xxl-offset-18 {
      margin-right: 75%; }
    .rtl .ant-col-xxl-push-17 {
      right: 70.83333333%; }
    .rtl .ant-col-xxl-pull-17 {
      left: 70.83333333%; }
    .rtl .ant-col-xxl-offset-17 {
      margin-right: 70.83333333%; }
    .rtl .ant-col-xxl-push-16 {
      right: 66.66666667%; }
    .rtl .ant-col-xxl-pull-16 {
      left: 66.66666667%; }
    .rtl .ant-col-xxl-offset-16 {
      margin-right: 66.66666667%; }
    .rtl .ant-col-xxl-push-15 {
      right: 62.5%; }
    .rtl .ant-col-xxl-pull-15 {
      left: 62.5%; }
    .rtl .ant-col-xxl-offset-15 {
      margin-right: 62.5%; }
    .rtl .ant-col-xxl-push-14 {
      right: 58.33333333%; }
    .rtl .ant-col-xxl-pull-14 {
      left: 58.33333333%; }
    .rtl .ant-col-xxl-offset-14 {
      margin-right: 58.33333333%; }
    .rtl .ant-col-xxl-push-13 {
      right: 54.16666667%; }
    .rtl .ant-col-xxl-pull-13 {
      left: 54.16666667%; }
    .rtl .ant-col-xxl-offset-13 {
      margin-right: 54.16666667%; }
    .rtl .ant-col-xxl-push-12 {
      right: 50%; }
    .rtl .ant-col-xxl-pull-12 {
      left: 50%; }
    .rtl .ant-col-xxl-offset-12 {
      margin-right: 50%; }
    .rtl .ant-col-xxl-push-11 {
      right: 45.83333333%; }
    .rtl .ant-col-xxl-pull-11 {
      left: 45.83333333%; }
    .rtl .ant-col-xxl-offset-11 {
      margin-right: 45.83333333%; }
    .rtl .ant-col-xxl-push-10 {
      right: 41.66666667%; }
    .rtl .ant-col-xxl-pull-10 {
      left: 41.66666667%; }
    .rtl .ant-col-xxl-offset-10 {
      margin-right: 41.66666667%; }
    .rtl .ant-col-xxl-push-9 {
      right: 37.5%; }
    .rtl .ant-col-xxl-pull-9 {
      left: 37.5%; }
    .rtl .ant-col-xxl-offset-9 {
      margin-right: 37.5%; }
    .rtl .ant-col-xxl-push-8 {
      right: 33.33333333%; }
    .rtl .ant-col-xxl-pull-8 {
      left: 33.33333333%; }
    .rtl .ant-col-xxl-offset-8 {
      margin-right: 33.33333333%; }
    .rtl .ant-col-xxl-push-7 {
      right: 29.16666667%; }
    .rtl .ant-col-xxl-pull-7 {
      left: 29.16666667%; }
    .rtl .ant-col-xxl-offset-7 {
      margin-right: 29.16666667%; }
    .rtl .ant-col-xxl-push-6 {
      right: 25%; }
    .rtl .ant-col-xxl-pull-6 {
      left: 25%; }
    .rtl .ant-col-xxl-offset-6 {
      margin-right: 25%; }
    .rtl .ant-col-xxl-order-6 {
      order: 6; }
    .rtl .ant-col-xxl-push-5 {
      right: 20.83333333%; }
    .rtl .ant-col-xxl-pull-5 {
      left: 20.83333333%; }
    .rtl .ant-col-xxl-offset-5 {
      margin-right: 20.83333333%; }
    .rtl .ant-col-xxl-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .rtl .ant-col-xxl-push-4 {
      right: 16.66666667%; }
    .rtl .ant-col-xxl-pull-4 {
      left: 16.66666667%; }
    .rtl .ant-col-xxl-offset-4 {
      margin-right: 16.66666667%; }
    .rtl .ant-col-xxl-push-3 {
      right: 12.5%; }
    .rtl .ant-col-xxl-pull-3 {
      left: 12.5%; }
    .rtl .ant-col-xxl-offset-3 {
      margin-right: 12.5%; }
    .rtl .ant-col-xxl-push-2 {
      right: 8.33333333%; }
    .rtl .ant-col-xxl-pull-2 {
      left: 8.33333333%; }
    .rtl .ant-col-xxl-offset-2 {
      margin-right: 8.33333333%; }
    .rtl .ant-col-xxl-push-1 {
      right: 4.16666667%; }
    .rtl .ant-col-xxl-pull-1 {
      left: 4.16666667%; }
    .rtl .ant-col-xxl-offset-1 {
      margin-right: 4.16666667%; }
    .rtl .ant-col-push-0 {
      right: auto; }
    .rtl .ant-col-pull-0 {
      left: auto; }
    .rtl .ant-col-xxl-push-0 {
      right: auto; }
    .rtl .ant-col-xxl-pull-0 {
      left: auto; }
    .rtl .ant-col-xxl-offset-0 {
      margin-right: 0; } }
  .rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    right: 16px; }
  .rtl .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-right: 12px; }
  .rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
  .rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
  .rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
  .rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
  .rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
  .rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
  .rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
  .rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
  .rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .rtl .ant-calendar-picker-clear,
  .rtl .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 12px;
    right: auto;
    top: 50%;
    line-height: 14px;
    font-size: 12px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .rtl .ant-calendar-picker-small .ant-calendar-picker-clear,
  .rtl .ant-calendar-picker-small .ant-calendar-picker-icon {
    left: 8px; }
  .rtl .ant-calendar {
    position: relative;
    outline: none;
    width: 280px;
    border: 1px solid #fff;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    line-height: 1.5; }
  .rtl .ant-calendar-header .ant-calendar-prev-month-btn:after {
    content: '\2039'; }
  .rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    position: relative;
    margin-left: 5px;
    padding-right: 5px; }
  .rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
    content: " ";
    position: absolute;
    top: -1px;
    right: 5px;
    width: 24px;
    height: 24px;
    border: 1px solid #bcbcbc;
    border-radius: 2px; }
  .rtl .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .rtl .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .rtl .ant-calendar .ant-calendar-clear-btn {
    display: none;
    position: absolute;
    left: 5px;
    text-indent: -76px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0; }
  .rtl .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
  .rtl .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent; }
  .rtl .ant-calendar-range-left {
    float: right; }
  .rtl .ant-calendar-range-left .ant-calendar-time-picker-inner {
    border-left: 1.5px solid #e8e8e8; }
  .rtl .ant-calendar-range-right {
    float: left; }
  .rtl .ant-calendar-range-right .ant-calendar-time-picker-inner {
    border-right: 1.5px solid #e8e8e8; }
  .rtl .ant-calendar-range-middle {
    position: absolute;
    right: 50%;
    width: 20px;
    margin-right: -132px;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45); }
  .rtl .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: -118px; }
  .rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
    margin-right: -12px; }
  .rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-right: 0; }
  .rtl .ant-calendar-range .ant-calendar-input,
  .rtl .ant-calendar-range .ant-calendar-time-picker-input {
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: 24px;
    border: 0;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0; }
  .rtl .ant-calendar-range .ant-calendar-in-range-cell:before {
    content: '';
    display: block;
    background: #e6f7ff;
    border-radius: 0;
    border: 0;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 0;
    left: 0; }
  .rtl div.ant-calendar-range-quick-selector {
    text-align: right; }
  .rtl div.ant-calendar-range-quick-selector > a {
    margin-left: 8px; }
  .rtl .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    margin-left: 8px; }
  .rtl .ant-calendar-time-picker-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    background-clip: padding-box;
    line-height: 1.5;
    overflow: hidden;
    width: 100%; }
  .rtl .ant-calendar-time-picker-select {
    float: right;
    font-size: 14px;
    border-left: 1px solid #e8e8e8;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: 226px; }
  .rtl .ant-calendar-time-picker-select:first-child {
    border-right: 0;
    margin-right: 0; }
  .rtl .ant-calendar-time-picker-select:last-child {
    border-left: 0; }
  .rtl .ant-calendar-time .ant-calendar-footer-btn {
    text-align: left; }
  .rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
    float: right;
    margin: 0; }
  .rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    display: inline-block;
    margin-left: 8px; }
  .rtl .ant-calendar-month-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    right: 7px; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
    content: '\AB'; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    left: 7px; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
    right: 29px; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
    content: '\2039'; }
  .rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
    left: 29px; }
  .rtl .ant-calendar-year-panel {
    position: absolute;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    right: 7px; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    left: 7px; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
    right: 29px; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
    content: '\2039'; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
    left: 29px; }
  .rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
    content: '\203A'; }
  .rtl .ant-calendar-decade-panel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none; }
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    right: 7px; }
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
    content: '\AB'; }
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    left: 7px; }
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
    right: 29px; }
  .rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
    left: 29px; }
  .rtl .ant-time-picker-panel-inner {
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: right;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    overflow: hidden;
    right: -2px; }
  .rtl .ant-time-picker-panel-clear-btn {
    position: absolute;
    left: 8px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0; }
  .rtl .ant-time-picker-panel-select {
    float: right;
    font-size: 14px;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 56px;
    overflow: hidden;
    position: relative;
    max-height: 192px; }
  .rtl .ant-time-picker-panel-select:first-child {
    border-right: 0;
    margin-right: 0; }
  .rtl .ant-time-picker-panel-select:last-child {
    border-left: 0; }
  .rtl .ant-time-picker-panel-select li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 12px 0 0;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: right;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: background 0.3s;
    transition: background 0.3s; }
  .rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
  .rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
  .rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
  .rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
  .rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
  .rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
  .rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
  .rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
  .rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .rtl .ant-time-picker-icon {
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 14px;
    height: 14px;
    line-height: 14px;
    left: 11px;
    right: auto;
    color: rgba(0, 0, 0, 0.25);
    top: 50%;
    margin-top: -7px; }
  .rtl .ant-time-picker-small .ant-time-picker-icon {
    left: 7px; }
  .rtl .ant-divider-horizontal.ant-divider-with-text-left {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0; }
  .rtl .ant-divider-horizontal.ant-divider-with-text-left:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .rtl .ant-divider-horizontal.ant-divider-with-text-left:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .rtl .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
    display: inline-block;
    padding: 0 10px; }
  .rtl .ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin: 16px 0; }
  .rtl .ant-divider-horizontal.ant-divider-with-text-right:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 95%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .rtl .ant-divider-horizontal.ant-divider-with-text-right:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 5%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .rtl .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
    display: inline-block;
    padding: 0 10px; }
  .rtl .ant-dropdown-menu {
    text-align: right; }
  .rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    left: 8px; }
  .rtl .ant-dropdown-menu-submenu-title {
    padding-left: 26px; }
  .rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    top: 0;
    right: 100%;
    position: absolute;
    min-width: 100%;
    margin-right: 4px;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0; }
  .rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
  .rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
  .rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
  .rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
  .rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
  .rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
  .rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
  .rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
  .rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .rtl .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-form-item-required:before {
    display: inline-block;
    margin-left: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d; }
  .rtl .ant-form-item-label {
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap; }
  .rtl .ant-form-text {
    display: inline-block;
    padding-left: 8px; }
  .rtl form .has-feedback .ant-input {
    padding-left: 24px; }
  .rtl form .has-feedback > .ant-select .ant-select-arrow,
  .rtl form .has-feedback > .ant-select .ant-select-selection__clear,
  .rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
    left: 28px; }
  .rtl form .has-feedback > .ant-select .ant-select-selection-selected-value,
  .rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
    padding-left: 42px; }
  .rtl form .has-feedback .ant-cascader-picker-arrow {
    margin-left: 17px; }
  .rtl form .has-feedback .ant-cascader-picker-clear {
    left: 28px; }
  .rtl form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    left: 28px; }
  .rtl form .has-feedback .ant-calendar-picker-icon,
  .rtl form .has-feedback .ant-time-picker-icon,
  .rtl form .has-feedback .ant-calendar-picker-clear,
  .rtl form .has-feedback .ant-time-picker-clear {
    left: 28px; }
  .rtl form .ant-radio-inline,
  .rtl form .ant-checkbox-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
    margin-right: 8px; }
  .rtl form .ant-radio-inline:first-child,
  .rtl form .ant-checkbox-inline:first-child {
    margin-right: 0; }
  .rtl form .ant-checkbox-vertical,
  .rtl form .ant-radio-vertical {
    display: block; }
  .rtl form .ant-checkbox-vertical + .ant-checkbox-vertical,
  .rtl form .ant-radio-vertical + .ant-radio-vertical {
    margin-right: 0; }
  .rtl form .ant-input-number + .ant-form-text {
    margin-right: 8px; }
  .rtl .ant-input-group-wrap .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .rtl .ant-input-group-wrap .ant-select-selection:hover {
    border-color: #d9d9d9; }
  .rtl .ant-input-group-wrap .ant-select-selection--single {
    margin-right: -1px;
    height: 40px;
    background-color: #eee; }
  .rtl .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
    padding-right: 8px;
    padding-left: 25px; }
  .rtl .ant-form-vertical .ant-form-item-label,
  .rtl .ant-col-24.ant-form-item-label,
  .rtl .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5; }
  @media (max-width: 575px) {
    .rtl .ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; }
    .rtl .ant-col-xs-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; } }
  @media (max-width: 767px) {
    .rtl .ant-col-sm-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; } }
  @media (max-width: 991px) {
    .rtl .ant-col-md-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; } }
  @media (max-width: 1199px) {
    .rtl .ant-col-lg-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; } }
  @media (max-width: 1599px) {
    .rtl .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: right;
      line-height: 1.5; } }
  .rtl .ant-form-inline .ant-form-item {
    display: inline-block;
    margin-left: 16px;
    margin-bottom: 0; }
  .rtl .has-success.has-feedback .ant-form-item-children:after,
  .rtl .has-warning.has-feedback .ant-form-item-children:after,
  .rtl .has-error.has-feedback .ant-form-item-children:after,
  .rtl .is-validating.has-feedback .ant-form-item-children:after {
    position: absolute;
    top: 50%;
    left: 0;
    visibility: visible;
    pointer-events: none;
    width: 32px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    text-align: center;
    font-size: 14px;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    font-family: 'anticon';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    z-index: 1; }
  .rtl .ant-input-number-handler-up-inner,
  .rtl .ant-input-number-handler-down-inner {
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    width: 12px;
    height: 12px;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    display: inline-block;
    font-size: 12px;
    font-size: 7px \9;
    -webkit-transform: scale(0.58333333) rotate(0deg);
    transform: scale(0.58333333) rotate(0deg);
    left: 4px;
    color: rgba(0, 0, 0, 0.45); }
  .rtl .ant-layout-sider-right {
    order: 1; }
  .rtl .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    left: -36px;
    text-align: center;
    width: 36px;
    height: 42px;
    line-height: 42px;
    background: #001529;
    color: #fff;
    font-size: 18px;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease; }
  .rtl .ant-list-pagination {
    margin-top: 24px;
    text-align: left; }
  .rtl .ant-list-more button {
    padding-right: 32px;
    padding-left: 32px; }
  .rtl .ant-list-item-meta-avatar {
    margin-left: 16px; }
  .rtl .ant-list-item-action {
    font-size: 0;
    flex: 0 0 auto;
    margin-right: 48px;
    padding: 0;
    list-style: none; }
  .rtl .ant-list-item-action > li:first-child {
    padding-right: 0; }
  .rtl .ant-list-item-action-split {
    background-color: #e8e8e8;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 14px; }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 58px; }
  .rtl .ant-list-vertical .ant-list-item-action {
    margin-right: auto; }
  .rtl .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-right: 0; }
  .rtl .ant-list-bordered .ant-list-header {
    padding-right: 24px;
    padding-left: 24px; }
  .rtl .ant-list-bordered .ant-list-footer {
    padding-right: 24px;
    padding-left: 24px; }
  .rtl .ant-list-bordered .ant-list-item {
    border-bottom: 1px solid #e8e8e8;
    padding-right: 24px;
    padding-left: 24px; }
  .rtl .ant-list-bordered .ant-list-pagination {
    margin: 16px 24px; }
  .rtl .ant-list-bordered.ant-list-sm .ant-list-item {
    padding-right: 16px;
    padding-left: 16px; }
  @media screen and (max-width: 768px) {
    .rtl .ant-list-item-action {
      margin-right: 24px; }
    .rtl .ant-list-vertical .ant-list-item-extra {
      margin-right: 24px; } }
  @media screen and (max-width: 480px) {
    .rtl .ant-list-item-action {
      margin-right: 12px; }
    .rtl .ant-list-vertical .ant-list-item-extra {
      margin-right: 0; } }
  .rtl .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    right: 50%;
    margin: -10px; }
  .rtl .ant-spin-blur:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.3;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 10; }
  .rtl .ant-spin-dot i:nth-child(1) {
    right: 0;
    top: 0; }
  .rtl .ant-spin-dot i:nth-child(2) {
    left: 0;
    top: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  .rtl .ant-spin-dot i:nth-child(3) {
    left: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s; }
  .rtl .ant-spin-dot i:nth-child(4) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s; }
  .rtl .ant-pagination-item {
    margin-left: 8px; }
  .rtl .ant-pagination-item-link {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .rtl .ant-pagination-prev,
  .rtl .ant-pagination-jump-prev,
  .rtl .ant-pagination-jump-next {
    margin-left: 8px; }
  .rtl .ant-pagination-options {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px; }
  .rtl .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    margin-left: 8px; }
  .rtl .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    margin-left: 8px;
    height: 24px; }
  .rtl .ant-pagination-simple .ant-pagination-simple-pager input {
    margin-left: 8px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    outline: none;
    padding: 0 6px;
    height: 100%;
    text-align: center;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; }
  .rtl .ant-pagination.mini .ant-pagination-jump-prev,
  .rtl .ant-pagination.mini .ant-pagination-jump-next {
    height: 24px;
    line-height: 24px;
    margin-left: 0; }
  .rtl .ant-pagination.mini .ant-pagination-options {
    margin-right: 2px; }
  .rtl .ant-mention-dropdown {
    right: -9999px; }
  .rtl .ant-menu-item .anticon,
  .rtl .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-left: 10px;
    -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: absolute;
    top: 50%;
    left: 16px;
    right: auto;
    width: 10px; }
  .rtl .ant-tooltip-placement-right,
  .rtl .ant-tooltip-placement-rightTop,
  .rtl .ant-tooltip-placement-rightBottom {
    padding-right: 8px; }
  .rtl .ant-tooltip-placement-left,
  .rtl .ant-tooltip-placement-leftTop,
  .rtl .ant-tooltip-placement-leftBottom {
    padding-left: 8px; }
  .rtl .ant-tooltip-inner {
    text-align: right; }
  .rtl .ant-tooltip-placement-top .ant-tooltip-arrow {
    right: 50%;
    margin-right: -5px; }
  .rtl .ant-tooltip-placement-topleft .ant-tooltip-arrow {
    right: 16px; }
  .rtl .ant-tooltip-placement-topright .ant-tooltip-arrow {
    left: 16px; }
  .rtl .ant-tooltip-placement-right .ant-tooltip-arrow,
  .rtl .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
  .rtl .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    right: 3px;
    border-width: 5px 0 5px 5px;
    border-left-color: rgba(0, 0, 0, 0.75); }
  .rtl .ant-tooltip-placement-left .ant-tooltip-arrow,
  .rtl .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
  .rtl .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    left: 3px;
    border-width: 5px 5px 5px 0;
    border-right-color: rgba(0, 0, 0, 0.75); }
  .rtl .ant-tooltip-placement-bottom .ant-tooltip-arrow {
    right: 50%;
    margin-right: -5px; }
  .rtl .ant-tooltip-placement-bottomleft .ant-tooltip-arrow {
    right: 16px; }
  .rtl .ant-tooltip-placement-bottomright .ant-tooltip-arrow {
    left: 16px; }
  .rtl .ant-message {
    font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 100%;
    top: 16px;
    right: 0;
    pointer-events: none; }
  .rtl .ant-message .anticon {
    margin-left: 8px;
    font-size: 16px;
    top: 1px;
    position: relative; }
  .rtl .ant-modal-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    outline: 0; }
  .rtl .ant-modal-close {
    cursor: pointer;
    border: 0;
    background: transparent;
    position: absolute;
    left: 0;
    right: auto;
    top: 8px;
    z-index: 10;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s;
    color: rgba(0, 0, 0, 0.45);
    outline: 0;
    padding: 0; }
  .rtl .ant-form-item-control .ant-form-explain {
    bottom: -20px;
    white-space: nowrap; }
  .rtl .ant-modal-footer {
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: left;
    border-radius: 0 0 4px 4px;
    text-align: center; }
  .rtl .ant-modal-footer button + button {
    margin-right: 8px;
    margin-bottom: 0; }
  .rtl .ant-modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #373737;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    z-index: 1000;
    filter: alpha(opacity=50); }
  .rtl .ant-confirm-body .ant-confirm-content {
    margin-right: 38px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 8px; }
  .rtl .ant-confirm-body > .anticon {
    font-size: 22px;
    margin-left: 16px;
    float: right; }
  .rtl .ant-confirm .ant-confirm-btns {
    margin-top: 24px;
    float: left; }
  .rtl .ant-confirm .ant-confirm-btns button + button {
    margin-right: 8px;
    margin-bottom: 0; }
  .rtl .ant-notification {
    margin-left: 24px; }
  .rtl .ant-notification-topleft,
  .rtl .ant-notification-bottomleft {
    margin-right: 24px;
    margin-left: 0; }
  .rtl .ant-notification-topleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .rtl .ant-notification-bottomleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .rtl .ant-notification-topleft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .rtl .ant-notification-bottomleft .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationleftFadeIn;
    animation-name: NotificationleftFadeIn; }
  .rtl .ant-notification-notice-closable .ant-notification-notice-message {
    padding-left: 24px; }
  .rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 16px;
    margin-right: 48px;
    margin-bottom: 4px; }
  .rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-right: 48px;
    font-size: 14px; }
  .rtl .ant-notification-notice-icon {
    position: absolute;
    font-size: 24px;
    line-height: 24px;
    margin-right: 4px; }
  .rtl .ant-notification-notice-close {
    position: absolute;
    left: 22px;
    right: auto;
    top: 16px;
    color: rgba(0, 0, 0, 0.45);
    outline: none; }
  .rtl .ant-notification-notice-btn {
    float: left;
    margin-top: 16px; }
@-webkit-keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px; }
  100% {
    right: 0;
    opacity: 1; } }
@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px; }
  100% {
    right: 0;
    opacity: 1; } }
@-webkit-keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px; }
  100% {
    left: 0;
    opacity: 1; } }
@keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px; }
  100% {
    left: 0;
    opacity: 1; } }
  .rtl .ant-popover {
    right: 0;
    text-align: right; }
  .rtl .ant-popover-placement-top,
  .rtl .ant-popover-placement-topleft,
  .rtl .ant-popover-placement-topright {
    padding-bottom: 9px; }
  .rtl .ant-popover-placement-right,
  .rtl .ant-popover-placement-rightTop,
  .rtl .ant-popover-placement-rightBottom {
    padding-right: 9px; }
  .rtl .ant-popover-placement-bottom,
  .rtl .ant-popover-placement-bottomleft,
  .rtl .ant-popover-placement-bottomright {
    padding-top: 9px; }
  .rtl .ant-popover-placement-left,
  .rtl .ant-popover-placement-leftTop,
  .rtl .ant-popover-placement-leftBottom {
    padding-left: 9px; }
  .rtl .ant-popover-message-title {
    padding-right: 22px; }
  .rtl .ant-popover-buttons {
    text-align: left;
    margin-bottom: 4px; }
  .rtl .ant-popover-buttons button {
    margin-right: 8px; }
  .rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
    bottom: 6px;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07); }
  .rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    right: 50%;
    -webkit-transform: translateX(50%) rotate(-45deg);
    transform: translateX(50%) rotate(-45deg); }
  .rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow {
    right: 16px; }
  .rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
    left: 16px; }
  .rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    right: 6px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07); }
  .rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg); }
  .rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px; }
  .rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 12px; }
  .rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
    top: 6px;
    box-shadow: 1px -1px 4px rgba(0, 0, 0, 0.06); }
  .rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    right: 50%;
    -webkit-transform: translateX(50%) rotate(-45deg);
    transform: translateX(50%) rotate(-45deg); }
  .rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow {
    right: 16px; }
  .rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
    left: 16px; }
  .rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
  .rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    left: 6px;
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07); }
  .rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg); }
  .rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px; }
  .rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 12px; }
  .rtl .ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-left: 0;
    padding-left: 0; }
  .rtl .ant-progress-show-info .ant-progress-outer {
    padding-left: calc(2em + 8px);
    margin-left: calc(-2em - 8px); }
  .rtl .ant-progress-success-bg {
    background-color: #52c41a;
    position: absolute;
    top: 0;
    right: 0; }
  .rtl .ant-progress-text {
    word-break: normal;
    width: 2em;
    text-align: right;
    font-size: 1em;
    margin-right: 8px;
    vertical-align: middle;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1; }
  .rtl .ant-progress-status-active .ant-progress-bg:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px;
    -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite; }
  .rtl .ant-progress-circle .ant-progress-text {
    right: 40px;
    margin: 0;
    color: rgba(0, 0, 0, 0.65); }
  .rtl .ant-rate-star {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: inherit;
    cursor: pointer; }
  .rtl .ant-rate-star-first {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0; }
  .rtl .ant-rate-text {
    margin-right: 8px;
    display: inline-block;
    font-size: 14px; }
  .rtl .ant-slider-vertical .ant-slider-handle {
    margin-right: -5px;
    margin-bottom: -7px; }
  .rtl .ant-slider-vertical .ant-slider-mark {
    top: 0;
    right: 12px;
    width: 18px;
    height: 100%; }
  .rtl .ant-slider-vertical .ant-slider-mark-text {
    right: 4px;
    white-space: nowrap; }
  .rtl .ant-slider-vertical .ant-slider-step {
    width: 4px;
    height: 100%; }
  .rtl .ant-slider-vertical .ant-slider-dot {
    top: auto;
    right: 2px;
    margin-bottom: -4px; }
  .rtl .ant-slider-mark {
    position: absolute;
    top: 14px;
    right: 0;
    width: 100%;
    font-size: 14px; }
  .rtl .ant-slider-dot {
    position: absolute;
    top: -2px;
    margin-right: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e8e8e8;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle; }
  .rtl .ant-slider-dot:first-child {
    margin-right: -4px; }
  .rtl .ant-slider-dot:last-child {
    margin-right: -4px; }
  .rtl .ant-steps-item-icon {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 32px;
    font-size: 16px;
    margin-left: 8px;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .rtl .ant-steps-item-tail {
    position: absolute;
    right: 0;
    width: 100%;
    top: 12px;
    padding: 0 10px; }
  .rtl .ant-steps-item-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    padding-left: 16px;
    position: relative;
    line-height: 32px; }
  .rtl .ant-steps-item-title:after {
    content: '';
    height: 1px;
    width: 9999px;
    background: #e8e8e8;
    display: block;
    position: absolute;
    top: 16px;
    right: 100%; }
  .rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-left: 16px;
    white-space: nowrap; }
  .rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
    margin-left: 0; }
  .rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
    padding-left: 0; }
  .rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    font-size: 24px;
    line-height: 32px;
    top: 0;
    right: 0.5px;
    width: 32px;
    height: 32px; }
  .rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-left: 12px; }
  .rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
    margin-left: 0; }
  .rtl .ant-steps-small .ant-steps-item-title {
    font-size: 14px;
    line-height: 24px;
    padding-left: 12px; }
  .rtl .ant-steps-vertical .ant-steps-item-icon {
    float: right;
    margin-left: 16px; }
  .rtl .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px; }
  .rtl .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 30px 0 6px; }
  @media (max-width: 480px) {
    .rtl .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
      float: right;
      margin-left: 16px; }
    .rtl .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
      position: absolute;
      right: 16px;
      top: 0;
      height: 100%;
      width: 1px;
      padding: 38px 0 6px; }
    .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
      position: absolute;
      right: 12px;
      top: 0;
      padding: 30px 0 6px; }
    .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
      line-height: 24px; } }
  .rtl .ant-steps-label-vertical .ant-steps-item-tail {
    padding: 0 24px;
    margin-right: 48px; }
  .rtl .ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-right: 36px; }
  .rtl .ant-steps-label-vertical .ant-steps-item-title {
    padding-left: 0; }
  .rtl .ant-steps-label-vertical .ant-steps-item-description {
    text-align: right; }
  .rtl .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 2px; }
  .rtl .ant-steps-dot .ant-steps-item-icon {
    padding-left: 0;
    width: 8px;
    height: 8px;
    line-height: 8px;
    border: 0;
    margin-right: 67px;
    background: transparent; }
  .rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
    float: right;
    width: 100%;
    height: 100%; }
  .rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
    content: "";
    background: rgba(0, 0, 0, 0.001);
    width: 60px;
    height: 32px;
    position: absolute;
    top: -12px;
    right: -26px; }
  .rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-right: 0;
    margin-top: 8px; }
  .rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
    margin: 0;
    right: -9px;
    top: 2px;
    padding: 22px 0 4px; }
  .rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    right: 0; }
  .rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    right: -2px; }
  .rtl .ant-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: right;
    border-radius: 4px 4px 0 0; }
  .rtl .ant-table-thead > tr > th {
    text-align: right; }
  .rtl .ant-table-thead > tr > th .anticon-filter,
  .rtl .ant-table-thead > tr > th .ant-table-filter-icon {
    position: absolute;
    right: auto;
    left: 0;
    margin-right: 0px;
    margin-left: 8px;
    vertical-align: text-bottom; }
  .rtl .ant-table-thead > tr:first-child > th:first-child {
    text-align: center; }
  .rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters, .rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 20px !important;
    padding-left: 30px !important; }
  .rtl .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
    margin-right: 4px; }
  .rtl .ant-table-thead > tr:first-child > th:first-child {
    border-top-right-radius: 4px; }
  .rtl .ant-table-thead > tr:first-child > th:last-child {
    border-top-left-radius: 4px; }
  .rtl .ant-table-footer:before {
    content: '';
    height: 1px;
    background: #fafafa;
    position: absolute;
    top: -1px;
    width: 100%;
    right: 0; }
  .rtl .ant-table.ant-table-bordered .ant-table-title {
    border: 1px solid #e8e8e8;
    padding-right: 16px;
    padding-left: 16px; }
  .rtl .ant-table-thead > tr > th.ant-table-selection-column-custom {
    padding-right: 16px;
    padding-left: 0; }
  .rtl .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
  .rtl .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
    margin-left: 0; }
  .rtl .ant-table-loading .ant-table-spin-holder {
    height: 20px;
    line-height: 20px;
    right: 50%;
    top: 50%;
    margin-right: -30px;
    position: absolute; }
  .rtl .ant-table-column-sorter {
    position: relative;
    margin-right: 8px;
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    text-align: center;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.45); }
  .rtl .ant-table-column-sorter-up:after,
  .rtl .ant-table-column-sorter-down:after {
    position: absolute;
    content: '';
    height: 30px;
    width: 14px;
    right: 0; }
  .rtl .ant-table-bordered .ant-table-header > table,
  .rtl .ant-table-bordered .ant-table-body > table,
  .rtl .ant-table-bordered .ant-table-fixed-left table,
  .rtl .ant-table-bordered .ant-table-fixed-right table {
    border: 1px solid #e8e8e8;
    border-left: 0;
    border-bottom: 0; }
  .rtl .ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .rtl .ant-table-bordered .ant-table-thead > tr > th,
  .rtl .ant-table-bordered .ant-table-tbody > tr > td {
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-table-placeholder .anticon {
    margin-left: 4px; }
  .rtl .ant-table-pagination.ant-pagination {
    margin: 16px 0; }
  .rtl .ant-table-filter-dropdown {
    min-width: 96px;
    margin-right: -8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .rtl .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
    padding-left: 0; }
  .rtl .ant-table-filter-dropdown-link.confirm {
    float: right; }
  .rtl .ant-table-filter-dropdown-link.clear {
    float: left; }
  .rtl .ant-table-selection-select-all-custom {
    margin-left: 4px !important; }
  .rtl .ant-table-selection-menu {
    min-width: 96px;
    margin-top: 5px;
    margin-right: -30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .rtl .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-left: 8px; }
  .rtl .ant-table-fixed-left,
  .rtl .ant-table-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
    -webkit-transition: -webkit-box-shadow 0.3s ease;
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    border-radius: 0; }
  .rtl .ant-table-fixed-left table,
  .rtl .ant-table-fixed-right table {
    width: auto;
    background: #fff; }
  .rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
  .rtl .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
    border-radius: 0; }
  .rtl .ant-table-fixed-left {
    right: 0;
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15); }
  .rtl .ant-table-fixed-left .ant-table-header {
    overflow-y: hidden; }
  .rtl .ant-table-fixed-left .ant-table-body-inner {
    margin-left: -20px;
    padding-left: 20px; }
  .rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
    padding-left: 0; }
  .rtl .ant-table-fixed-left,
  .rtl .ant-table-fixed-left table {
    border-radius: 0 4px 0 0; }
  .rtl .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
    border-top-left-radius: 0; }
  .rtl .ant-table-fixed-right {
    left: 0;
    box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15); }
  .rtl .ant-table-fixed-right,
  .rtl .ant-table-fixed-right table {
    border-radius: 4px 0 0 0; }
  .rtl .ant-table-fixed-right .ant-table-expanded-row {
    color: transparent;
    pointer-events: none; }
  .rtl .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
    border-top-right-radius: 0; }
  .rtl .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
    box-shadow: none; }
  .rtl .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
    box-shadow: none; }
  .rtl .ant-table-small.ant-table-bordered {
    border-left: 0; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-title {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-content {
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-footer {
    border: 0;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-footer:before {
    display: none; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-placeholder {
    border-right: 0;
    border-bottom: 0; }
  .rtl .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
  .rtl .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
    border-left: none; }
  .rtl .ant-tag {
    margin-left: 8px;
    cursor: pointer;
    white-space: nowrap; }
  .rtl .ant-tag .anticon-cross {
    display: inline-block;
    font-size: 12px;
    font-size: 10px \9;
    -webkit-transform: scale(0.83333333) rotate(0deg);
    transform: scale(0.83333333) rotate(0deg);
    cursor: pointer;
    margin-right: 3px;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold; }
  .rtl .ant-timeline-item-tail {
    position: absolute;
    right: 4px;
    top: 0.75em;
    height: 100%;
    border-right: 2px solid #e8e8e8; }
  .rtl .ant-timeline-item-head-custom {
    right: 5px;
    width: auto; }
  .rtl .ant-timeline-item-last .ant-timeline-item-tail {
    border-right: 2px dotted #e8e8e8;
    display: none; }
  .rtl .ant-transfer-list-search-action {
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 28px;
    line-height: 32px;
    text-align: center; }
  .rtl .ant-transfer-list-header {
    right: 0;
    width: 100%; }
  .rtl .ant-transfer-list-header-title {
    position: absolute;
    left: 12px; }
  .rtl .ant-transfer-list-body-search-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    width: 100%; }
  .rtl .ant-transfer-list-content-item > span {
    padding-left: 0; }
  .rtl .ant-transfer-list-footer {
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; }
  .rtl .ant-select-tree-checkbox-checked:after {
    position: absolute;
    top: 0;
    right: 0; }
  .rtl .ant-select-tree-checkbox-inner {
    position: relative;
    top: 0; }
  .rtl .ant-select-tree-checkbox-inner:after {
    right: 4.57142857px;
    border-right: 0; }
  .rtl .ant-select-tree-checkbox-input {
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .rtl .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    content: ' ';
    -webkit-transform: scale(1);
    transform: scale(1);
    position: absolute;
    right: 2.42857143px;
    top: 5.92857143px;
    width: 9.14285714px;
    height: 1.14285714px; }
  .rtl .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s; }
  .rtl .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
    margin-right: 8px; }
  .rtl .ant-select-tree-checkbox-wrapper + span,
  .rtl .ant-select-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-select-tree-checkbox-group-item {
    display: inline-block;
    margin-left: 8px; }
  .rtl .ant-select-tree-checkbox-group-item:last-child {
    margin-left: 0; }
  .rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
    margin-right: 0; }
  .rtl .ant-select-tree-icon__open {
    margin-left: 2px;
    vertical-align: top; }
  .rtl .ant-select-tree-icon__close {
    margin-left: 2px;
    vertical-align: top; }
  .rtl .ant-tree-checkbox-checked:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #1890ff;
    content: '';
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .rtl .ant-tree-checkbox-inner {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .rtl .ant-tree-checkbox-inner:after {
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    position: absolute;
    right: 4.57142857px;
    top: 1.14285714px;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6); }
  .rtl .ant-tree-checkbox-input {
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .rtl .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    content: ' ';
    -webkit-transform: scale(1);
    transform: scale(1);
    position: absolute;
    right: 2.42857143px;
    top: 5.92857143px;
    width: 9.14285714px;
    height: 1.14285714px; }
  .rtl .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-right: 0;
    content: ' ';
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s; }
  .rtl .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
    margin-right: 8px; }
  .rtl .ant-tree-checkbox-wrapper + span,
  .rtl .ant-tree-checkbox + span {
    padding-right: 8px;
    padding-left: 8px; }
  .rtl .ant-tree-checkbox-group-item {
    display: inline-block;
    margin-left: 8px; }
  .rtl .ant-tree-checkbox-group-item:last-child {
    margin-left: 0; }
  .rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
    margin-right: 0; }
  .rtl .ant-tree li span.ant-tree-icon_loading {
    position: absolute;
    right: 0;
    top: 1px;
    background: #fff;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all .3s;
    transition: all .3s; }
  .rtl .ant-tree-icon__open {
    margin-left: 2px;
    vertical-align: top; }
  .rtl .ant-tree-icon__close {
    margin-left: 2px;
    vertical-align: top; }
  .rtl .ant-tree.ant-tree-show-line li:not(:last-child):before {
    content: ' ';
    width: 1px;
    border-right: 1px solid #d9d9d9;
    height: 100%;
    position: absolute;
    right: 12px;
    margin: 22px 0; }
  .rtl .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 22px;
    width: 100%;
    display: inline-block; }
  .rtl .ant-upload-list-item .anticon-cross {
    left: 4px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px; }
  .rtl .ant-upload-list-item-error .anticon-cross {
    opacity: 1;
    color: #f5222d !important; }
  .rtl .ant-upload-list-item-progress {
    line-height: 0;
    font-size: 14px;
    position: absolute;
    width: 100%;
    bottom: -12px;
    padding-right: 26px; }
  .rtl .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 8px;
    right: 8px;
    text-align: center; }
  .rtl .ant-upload-list-picture .ant-upload-list-item-icon,
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 36px;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -18px;
    margin-right: -18px; }
  .rtl .ant-upload-list-picture .ant-upload-list-item-name,
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 8px 0 0;
    line-height: 44px;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding-right: 48px;
    padding-left: 8px;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box; }
  .rtl .ant-upload-list-picture .ant-upload-list-item-progress,
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-right: 56px;
    margin-top: 0;
    bottom: 14px;
    width: calc(100% - 24px); }
  .rtl .ant-upload-list-picture .anticon-cross,
  .rtl .ant-upload-list-picture-card .anticon-cross {
    position: absolute;
    left: 8px;
    top: 8px;
    line-height: 1; }
  .rtl .ant-upload-list-picture-card .ant-upload-list-item {
    float: right;
    width: 104px;
    height: 104px;
    margin: 0 0 8px 8px; }
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    right: 50%;
    top: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    z-index: 10;
    white-space: nowrap;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-right: 0;
    bottom: 32px; }

/*# sourceMappingURL=style.css.map */
